<div class="container-fluid">

  <img src="assets/banners/aboutus.jpg" alt="first slide" style="display: block; width: 100%;">
  

</div>
 <h1> About Institute </h1>
<p>     <br>
  The Indian youth are facing serious problems of unemployment and underemployment. This is due to the phenomenal rise in population and lack of proper infrastructural facilities to provide good quality education which will earn them a good living. Although Government is taking massive efforts to improve the scenario, still majority of the population is not able to get basic infrastructural facilities for their career development. Keeping this in the mind, (Kewal Institute of Technical & Vocational Industrial Training) has designed courses carefully taking into account the actual need of the students and the applicability of the subject knowledge in the jobs they are going to undertake in the immediate future.
<br><br>
Vocational Education Programmes has the ability to create corporate ready workforce. Also, it offers end-to-end skill building programmes focusing on improving the employability quotient of aspirants. Skilled manpower is usually considered a sine qua non of industrialization. Skilled workers are not born; in fact every worker has the potential of becoming a skilled worker and this potential can be realized by providing adequate opportunities for Vocational Education and Training.
<br><br>
At KIT-VIT, Vocational education is imparted through Vocational Training Centers that prepares people for a specific trade. It directly develops expertise in techniques related to technology, skill and scientific technique to span all aspects of the trade. Vocational education is classified as using procedural knowledge. Vocational Education prepares people for specific trades, crafts and careers at various levels from a trade, a craft, technician, or a high professional practitioner position in careers such as Tourism & Hospitality, Engineering, Accountancy, Nursing, Alternative Medicine, Fire & Safety, and Teaching etc. Craft vocations are usually based on manual or practical activities and are traditionally non-academic but related to a specific trade, occupation.
<br><br>
KIT-VIT Launched Vocational Training Programs by two unique Modes-<br>
<b>(1) IITP (Industry Internship Training Programs)<br>
(2) WITP (Work Integrated Training Programs)</b><br><br>
For Above purposes KIT-VIT collaborate with Industries/Sectors so that Students can get tremendous opportunities for making a better career in related Industry or Sectors. Anyone can make their future in following booming industry in India & Abroad.
</p>
