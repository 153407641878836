
<h1> Regular Universities Details </h1>
<b>Regular universities in India </b> 

We understand the stress of a student hence provide personalized direction, support, and counselling to him/her, in the order they know their dream of analyzing at most colleges of both India and overseas to fulfil their livelihood fantasies that are postsecondary. We advise them at just about every step of this University placement procedure, beginning from your applying techniques to admissions in accommodation. Our intent would be always to help the students smoothly in the direction of its own targeted college/university in India and also abroad. We Supply a Vast Range of services to the pupils such as Higher education choice: Admission Guidance comes with a fleet of authorities that allow one to decide on the most in college/university in line with your profile, expedient location, and price range. We remedy the predicaments related to pick of the class for bigger research studies.
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT & NGP Educare Gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i> UGC, AICTE,  BCI, PCI, NCTE, INC Approved Universities Direct Admission Guidance & Consultancy providing by KeyWorld Education Services.<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i> Therefore, Join Your Hands With Us As a Student /Trainees, VTPs and Industry Skill Partner For inclusive growth of All.

<br>
<h1> Offered Regular Universities  </h1>

<!--Certicate Courses-->

<div id="accordion" class="myaccordion">
    
    <!--First Headming-->

    <div class="card">
  <div class="card-header" id="headingOne">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
 Himalayan Garhwal University
          <span class="fa-stack fa-lg">
                <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">


<!--Slider start-->
<p><img src="assets/education/brand/2.jpg" height="70" width="100"/> Himalayan Garhwal University (HGU) is established and incorporated by Government of Uttarakhand Act No. 33 of 2016. Vide Notification no. XXXVI (3)/2016/48(1)/2016) of Uttarakhand Shashan (Vidhayee & Sansdiya Karyavibhag) and under Section 2(f) of the University Grant Commission (UGC) Act, 1956. Himalayan Garhwal University is situated in a peaceful and pollution free environment close to nature of Garhwal ranges of Shivalik hills. The Campus of University is surrounded by natural panoramic views which provides an ideal environment for higher education.




<ul>
     <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.hgu.ac.in/" target="_blank"> wwww.hgu.ac.in </a>

             

   
 </ul>








 </div>
 </div>
 </div>



<!--Diploma Courses-->


    
    <!--2nd Headming-->
 
    <div class="card">
  <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
Himalayan University
          <span class="fa-stack fa-lg">
                <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>

    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">

<p><img src="assets/education/brand/hu.png" height="70" width="100"/> Himalayan University a name tagged to enhance the strength of Indian higher education while offering the quality and nurtureful education in almost every theme of major disciplines. Himalayan University being as a top ranking university of India commits to bring the student with bright future. We also conduct our counseling session under the operation head where we analyze the interest of the students as per their past education and experience and then offer the best suited career programs.

    <ul>
         <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.himalayanuniversity.com/" target="_blank"> https://www.himalayanuniversity.com/</a>
    
            
    
       
     </ul>
    

</div>
</div>
</div>


    <!--3rd Headming-->
 
    <div class="card">
  <div class="card-header" id="headingThree">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
 JS University
          <span class="fa-stack fa-lg">
                <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>

    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
      

    


        <p><img src="assets/education/brand/js.jpg" height="70" width="100"/> JS University, Shikohabad is the 'dream come true' of Shri Jagdish Singh Ji, through Sri Jagdish Jan Kalyan Educational Trust which is working seriously in the field of quality education for rural area students since long back. Dynamic educationist Dr. Sukesh Kumar working vigorously in expanding the Colleges of this Trust in terms of a well planned University. The State Government under the leadership of Shri Akhilesh Yadav, the Honorable Chief Minister of Uttar Pradesh took cognizance and introduced a Bill to the status of a University in March 2015 and christened it as J.S. University, Shikohabad, Firozabad. Hon'ble Chief Minister proposed, “With a view to encourage infusion of philanthropic capital in private sector to participate in the field of higher education and encourage competition to attract high quality faculty and students and continuously enhance quality it has been decided to establish and incorporate a teaching University by the name of J.S. University, Shikohabad, Firozabad by Shri Jagdish Jan Kalyan Educational Trust, Shikohabad, Firozabad..” We are working hard for this noble purpose. Our motto is –' विद्या विनोयोगदिवकासः' (Progress through proper application of knowledge.)




            <ul>
                 <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://jsu.edu.in/" target="_blank"> https://jsu.edu.in/ </a>
            
                         
            
               
             </ul>
            





     </div>
    </div>
    </div>


<!--4th Heading-->
 
<div class="card">
    <div class="card-header" id="headingFour">
        <h2 class="mb-0">
          <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
   FS University
            <span class="fa-stack fa-lg">
                  <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
            </span>
          </button>
        </h2>
      </div>
  
      <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
        <div class="card-body">
        
  <p><img src="assets/education/brand/fsu.jpg" height="70" width="100"/>  It was the year 2004, Dr. Dilip Yadav decided to fulfil the dream of his beloved
    father, Late (Shri) Fulan Singh, for establishing various institutes to provide quality
    education to the society of the region & beyond. FS University is a tribute to
    him which is an initiative of Fulan Singh Jan Kalyan Trust in an attempt to
    make the Shikohabad city of UP State, recognized destination for
    knowledge seekers from different spheres of life.
    " Fraternity of FS University pays tribute to our beloved visionary
    Patron Late (Shri) Fulan Singh Ji. "
    
   <ul>
         <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://fsu.edu.in/" target="_blank"> wwww.fsu.edu.in </a>
   </ul>
        </div>
        </div>
        </div>
    
<!--5th Heading-->
 
<div class="card">
    <div class="card-header" id="headingFive">
        <h2 class="mb-0">
          <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
   SunRise  University
            <span class="fa-stack fa-lg">
                  <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
            </span>
          </button>
        </h2>
      </div>
  
      <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
        <div class="card-body">
        
  <p><img src="assets/education/brand/sru.png" height="70" width="200"/> Sunrise University is situated on a sprawling campus of 35 acres in the National Capital Region Alwar which is also known as “Tiger Gate of Rajasthan”. It is established under Sunrise University Act, 2011 (Act No. 25 of 2011) and recognized by University Grant Commission u/s 2(f) of UGC Act, 1956. It is a multi-facility University, with jurisdiction spread over the state of Rajasthan. The University’s objectives are to disseminate and advanced knowledge by offering teaching and research facilities, to make provisions for studies at Undergraduate, Post Graduate and Integrated Courses / levels in Engineering and Technology, Science, Management, Commerce, Humanities, Social Science, Fine Arts, Library & Information Science, Computer Science & Application, Hotel Management, Fire and Safety Management,Law, Yoga and Naturopathy, Agriculture, Horticulture, Health and Allied Medical Science and many more courses in pipeline with the intent to promote interdisciplinary studies and research. The University aims to offer quality higher education with the focus on employability and promoting meaningful research and innovation.

    The Sunrise University operates under the guidance of the Chairperson – Adv. Subash Yadav, Registrar – Mr. Ajay Arya. The Board of Management & Academic Council under the leadership of several learned academicians, experienced administrators & Industrialists are regular contributors to its success story.
    <br>
    Good infrastructure facilities have been created to cope with the increase in enrolment of students in various on-campus programmes. New laboratory facilities in terms of space and quality equipments have been provided to cater to the needs of the learners and researchers. High-tech class rooms and digital library facilities are some of the latest technological facilities created in the University.
    <br>
    An Internal Quality Assurance Cell has been created to monitor the quality of academic output. The departments of study conducts national and international seminars, symposia, workshops and conferences so as to expose teachers & students of the University to new trends in teaching and research at the global level. A Students PDP Cell for on-campus students has been set up to develop the individual student’s personality.
   <ul>
         <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "http://sunriseuniversity.in/" target="_blank"> sunriseuniversity.in </a>
   </ul>
        </div>
        </div>
        </div>


<!--6th Heading-->
 
<div class="card">
    <div class="card-header" id="headingSix">
        <h2 class="mb-0">
          <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
  Skkim Prfessional  University
            <span class="fa-stack fa-lg">
                  <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
            </span>
          </button>
        </h2>
      </div>
  
      <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
        <div class="card-body">
        
  <p><img src="assets/education/brand/spu.jpg" height="70" width="200"/> Welcome To Sikkim Professional University! Sikkim Professional  University earlier named as Vinayaka Missions Sikkim University was established in the year 2008 by SPU (Act No.11 of 2008) of Sikkim State Assembly. It has been renamed as Sikkim Professional University videits Amendment Act, 2020 (Act No. 09 of 2020). Duly recognized by the University Grants Commission (UGC), Sikkim Professional University is a centre of quality higher learning aiming to produce highly capable graduates who given an opportunity can compete in today’s corporate world. SPU enables its graduates and post graduates students to avail of the opportunity to discover their own competencies with ever growing vistas of knowledge. SPU imparts quality educational model of international standards where students can equip themselves with different aspects of higher education shifting in an effective manner. Here, we design our course curriculum with the objective to easily synchronized with ever growing segments of business world. We always tempt to work on to-add on different guest lectures and seminars to match with course curriculum to develop the required skills and knowledge in our students so that they can benefit directly to the society at large.
   <ul>
         <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Details Click Here to Visit the University Website Link: :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.spu.ac/" target="_blank"> www.spu.ac </a>
   </ul>
        </div>
        </div>
        </div>


