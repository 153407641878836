import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-fashion-courses',
  templateUrl: './fashion-courses.component.html',
  styleUrls: ['./fashion-courses.component.css']
})
export class FashionCoursesComponent implements OnInit {

  constructor(private http: HttpClient) { }
  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    email: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required])
  });
    
 
      
  get f(){
    return this.myForm.controls;
  }
     
  onFileChange(event: { target: { files: string | any[]; }; }) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }
  ngOnInit(): void {
  }
  
  submit(){
    console.log(this.myForm.value);
  }}