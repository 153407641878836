<h1> Retail Sales Courses </h1>
<b>Career in Retail Sector : <b> 
किसी भी उत्पाद या व्यवसाय की सफलता उनकी सेल्स टीम पर  निर्भर करती है. सेल्स टीम किसी भी कंपनी की रीढ़ की हड्डी होती है और यह सुनिश्चित करती है कि व्यवसाय कितना  लाभ कमाएगा. लेकिन जब करियर विकल्पों की बात आती है तो सबसे महत्त्वपूर्ण डोमेन होने के बावजूद बहुत से छात्र सेल्स मैन या सेल्स प्रबंधक बनने में रूचि दिखाते हैं. हालांकि यह करियर के विकास और वेतन दोनों के संदर्भ में एक बहुत ही आकर्षक करियर विकल्प है
किसी भी कंपनी में सेल्स  सबसे अच्छा वेतन पाने वाली टीमों में से एक है. इसलिए, जब सेल्स टीम की बात आती है, तो उम्मीदवार के लिए वेतन संभावनाएं बहुत अच्छी होती हैं.और आप अपनी सेल्स के आधार पर इंसेंटिव भी हासिल कर सकते हैं. एक फ्रेशर के रूप में शुरू करने वाला व्यक्ति लगभग 2 से 3 लाख प्रतिवर्ष की उम्मीद कर सकता है जो कि आपके  अनुभव और ज्ञान प्राप्त करने के बाद तेजी से बढ़ सकता है. एक अनुभवी सेल्स मैनेजर 5 से 8 लाख प्रतिवर्ष के वेतन पर किसी भी कंपनी में काम कर सकता है.सेल्स में करियर की डिमांड कभी कम नहीं होने वाली है
<br>
रिटेल मैनेजमेंट में ग्राहको तक किसी उत्पाद को सीधे पहुँचाना होता है। सुपरमार्केट या हायपरमार्केट का मैनेजमेंट रिटेल मैनेजमेंट कहलाता है। रिटेल मैनेजमेंट के अंतर्गत सभी ब्रांड्स, उनकी स्ट्रेटजी और कस्टमर को अट्रैक्ट करने की जानकारी का अध्ययन किया जाता है। भारत में तेजी से खुल रहे शॉपिंग सेंटर, मल्टी स्टोरी मॉल्स के चलते रिटल मैनेजमेंट में करियर की बेहतरीन संभावनाओं का विस्तार हुआ है। आज रिटेलिंग व्यवसाय ग्लोबल इकोनॉमी का हिस्सा बन चुका है। आज रिटेल इंडस्ट्री देश की इकोनॉमी में महत्वपूर्ण रोल अदा कर रही है। रिटेल मैनेजमेंट के बारे में सीधे तौर पर बताया जाए तो बिजनेस इंडस्ट्री का प्रमुख हिस्सा जिसके अंतर्गत बेचे जाने वाले प्रोडक्ट एवं उपभोक्ता को उचित कीमत पर दी जाने वाली सेवाएं शामिल है।
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT & NGP Educare Gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> Therefore, Join Your Hands With Us As a Student /Trainees, VTPs and Industry Skill Partner For inclusive growth of All.


<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->


<!--TABLE OF Courses START HERE-->

<div class="row div-pad">
  <div class="container">
 <h1 class="headi1">Courses <span>& Eligibility</span></h1>

<!--DIPLOMA Courses START HERE-->

      <h3 style="text-align:center"> Diploma Courses</h3>

      <div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Certified Retail Sales Executive</td>
							<td>1-Year</td>
							<td>10th-Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
					
           
           


        					</tbody>
				</table>
			</div>


<!--DIPLOMA Courses END HERE-->
