import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evisitingcard',
  templateUrl: './evisitingcard.component.html',
  styleUrls: ['./evisitingcard.component.css']
})
export class EvisitingcardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
