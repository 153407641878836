import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-president-message',
  templateUrl: './president-message.component.html',
  styleUrls: ['./president-message.component.css']
})
export class PresidentMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
