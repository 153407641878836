<h1> Technical Trade Certificate & Engineering Courses </h1>
<b> Career In Technical Trade & Engineering : </b> There is immense requirement for skilled and semi- skilled workers in industries. Especially there are more chances for carpenter, sawyer, upholster, machinist, fitter, crane operator, mechanic, welder, turner, plumber, electrician, tool &die maker, mason, draftsman, maintenance mechanics (dairy, textile machinery etc.), patternmaker, mechanic (Tractor, Earthmover Machinery, motor vehicle), etc. who can join industries and workshops as workers. Various government and non-governmental vacancies are advertised in newspapers from time to time.
<br>
 If one is not interested in doing a job he/she can opt for self employment. Students of “Cutting & Sewing” trade can open tailor shop on small level and can even start their own production house on larger level. Students from “Hair and Skin care” trade can open their beauty parlors. Electricians, plumbers can start their repairing workshops. Motor mechanics can open their garages. Carpenters, fitters, can start their own shops or can work on daily wages. Almost all the banks have certain schemes for providing loans to such needy persons at low interest rates. Some of the suitable trades are baker & confectioner, fruit & vegetable processor, leather crafts trades (leather goods maker, footwear maker), photographer, health & slimming assistant, textile trades (weaver, knitter, printing knitter), hair dresser, dress designing, health & slimming assistant, desktop publishing operator etc. Self employment could fetch good income based on individual skills.
 <br>
 Engineering is one of the most sought-after career options amongst Indian students because of the lucrative career opportunities it offers. To ensure a good career in engineering, it is very important to choose the right engineering college. There are different specializations in Engineering, and each specialization has varied career prospects. Apart from choosing a good college, it is also important to choose the right specialization in B.Tech. This will help you in reaching your career goals and aspirations. 
<br>
Although numerous new specialisations have emerged in engineering, it is important to know the best engineering courses in India and reputed colleges offering these courses.

<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT & NGP Educare Gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> Therefore, Join Your Hands With Us As a Student /Trainees, VTPs and Industry Skill Partner For inclusive growth of All.


<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->


<!--TABLE OF Courses START HERE-->

<div class="row div-pad">
  <div class="container">
 <h1 class="headi1">Courses <span>& Eligibility</span></h1>
<h3 style="text-align:center">Certificate Courses</h3>
<div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Trade Certificate in Mechanical</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Trade Certificate in Electrical</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
            <tr>
							<td>3.</td>
							<td>Trade Certificate in Electronics</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr><tr>
							<td>4.</td>
							<td>Trade Certificate in Welding</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr><tr>
							<td>5.</td>
							<td>Trade Certificate in Plumbing</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
            <tr>
							<td>6.</td>
							<td>Trade Certificate in Carpenter</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>          
            
        					</tbody>
				</table>
			</div>

<!--DIPLOMA Courses START HERE-->

      <h3 style="text-align:center"> Diploma Courses</h3>

      <div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Diploma in Civil Engineering</td>
							<td>3-Year</td>
							<td>10th Pass</td>
              <td>Regular only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Diploma in Mechanical Engineering</td>
							<td>3-Year</td>
							<td>10th Pass</td>
              <td>Regular only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
           
            <tr>
							<td>3.</td>
							<td>Diploma in Electronics Engineering</td>
							<td>3-Year</td>
							<td>10th Pass</td>
              <td>Regular only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
           
            <tr>
							<td>4.</td>
							<td>Diploma in Electrical Engineering</td>
							<td>3-Year</td>
							<td>10th Pass</td>
              <td>Regular only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
            <tr>
							<td>5.</td>
							<td>Diploma in Automobile Engineering</td>
							<td>3-Year</td>
							<td>10th Pass</td>
              <td>Regular only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
            <tr>
							<td>6.</td>
							<td>Diploma in Computer Science</td>
							<td>3-Year</td>
							<td>10th Pass</td>
              <td>Regular only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
                                    


        					</tbody>
				</table>
			</div>


<!--DIPLOMA Courses END HERE-->


<!--Degree Courses START HERE-->

<h3 style="text-align:center"> Degree Courses</h3>

<div class="table-responsive table-bordered  table-hover  ">
  <table class="table-striped">
    <tbody>
      <tr>
        <th>Sr. No.</th>
        <th>Course Name</th>
        <th>Duration</th>
        <th>Eligibility</th>
        <th>Education Mode </th>
        <th scope="col">Action</th>
      </tr>
     
      <tr>
        <td>1.</td>
        <td>Bachelor of Technoloy (Any Trade) </td>
        <td>4-Year</td>
        <td>12th pass</td>
        <td>Regular </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>
      <tr>
        <td>2.</td>
        <td>Master of Technoloy(Any Trade) </td>
        <td>2-Year</td>
        <td>B.Tech/B.E.</td>
        <td>Regular only </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>

     

  </tbody>
  </table>
</div>


<!--DEGREE Courses END HERE-->


