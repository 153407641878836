import { Component, OnInit } from '@angular/core';
import {CarouselConfig} from 'ngx-bootstrap/carousel'


import { data, event } from 'jquery';
import { MyserviceService } from 'src/app/Login Services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 5000, noPause: true, showIndicators: true,
      pauseOnFocus:false,  slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      infinite: true, 
      noWrap:false, } }
  ]
})
export class HomeComponent implements OnInit {
  itemsPerSlide = 4;
  singleSlideOffset = true;
  showIndicators=false;
  noWrap =false;
 
  slides = [
    {image: 'assets/01.jpg'},
    {image: 'assets/02.jpg'},
    {image: 'assets/01.jpg'},
    {image: 'assets/01.jpg'},
    {image: 'assets/03.jpg'},
  ];



  dataArr:any;
  data: any;
  

  onSlideRangeChange(indexes: number[]): void {

    $(document).ready(function(){
      $('.counter-value').each(function(){
          $(this).prop('Counter',0).animate({
              Counter: $(this).text()
          },{
              duration: 3500,
              easing: 'swing',
              step: function (now){
                  $(this).text(Math.ceil(now));
              }
          });
      });
    });

    

   
  }
  constructor(
    private myservice:MyserviceService
) { }

  ngOnInit(): void {

  }

  newsGet()
  {
    this.myservice.newsGet(data).subscribe(res=>{
      this.dataArr=res;
      
    })

}}
