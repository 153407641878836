
<h1> Paramedical Courses </h1>
<b>Career in Paramedical Sector : </b> 

भारत में कई पैरामेडिकल कॉलेज हैं, जो स्नातक डिग्री के क्षेत्र में और स्नातक डिग्री और डिप्लोमा स्तर पर पाठ्यक्रम प्रदान करते हैं। पात्रता के बारे में बात करते हुए, किसी को मान्यता प्राप्त बोर्ड / विश्वविद्यालय से विज्ञान धारा के साथ 10 + 2 का स्तर पारित करना होगा।
<br>
12 वीं के बाद पैरामेडिकल पाठ्यक्रमों की सूची
पैरा मेडिकल पाठ्यक्रम 10 वीं, 12 वीं के बाद पूरा हो सकते हैं। पैरामेडिकल धारा में 10 वीं कक्षा के बाद विभिन्न पाठ्यक्रम हैं। ये पाठ्यक्रम लंबाई में भिन्न होते हैं, जैसे कि एक वर्ष के पाठ्यक्रम और छह महीने के पाठ्यक्रम। लोकप्रिय एक साल के पाठ्यक्रमों में से कुछ में रेडियोलॉजिस्ट, मेडिकल रिकॉर्ड तकनीशियन और ऑपरेशन थिएटर टेक्नोलॉजी शामिल हैं। आईसीयू तकनीशियन, ईसीजी तकनीशियन, फार्मेसी सहायक, सीटी तकनीशियन और एन्डोस्कोपी तकनीशियन, अपने 12 वीं कक्षा को पूरा करने के बाद कुछ छह महीने के पाठ्यक्रम कर सकते हैं।

<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT & NGP Educare Gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> Therefore, Join Your Hands With Us As a Student /Trainees, VTPs and Industry Skill Partner For inclusive growth of All.


<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->


<!--TABLE OF Courses START HERE-->

<div class="row div-pad">
  <div class="container">
 <h1 class="headi1">Courses <span>& Eligibility</span></h1>

<!--DIPLOMA Courses START HERE-->

      <h3 style="text-align:center"> Diploma Courses</h3>

      <div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Diploma in Medical Lab Technology</td>
							<td>1&2-Year</td>
							<td>12th-PCB/PCM</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
					
            <tr>
							<td>2.</td>
							<td>Diploma in Operation Theater Technology</td>
							<td>1-Year</td>
							<td>12th-PCB/PCM</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
            <tr>
							<td>3.</td>
							<td>Diploma in Community Health</td>
							<td>1-Year</td>
							<td>12th-PCB/PCM</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>



        					</tbody>
				</table>
			</div>


<!--DIPLOMA Courses END HERE-->


<!--Degree Courses START HERE-->

<h3 style="text-align:center"> Degree Courses</h3>

<div class="table-responsive table-bordered  table-hover  ">
  <table class="table-striped">
    <tbody>
      <tr>
        <th>Sr. No.</th>
        <th>Course Name</th>
        <th>Duration</th>
        <th>Eligibility</th>
        <th>Education Mode </th>
        <th scope="col">Action</th>
      </tr>
      <tr>
        <td>1.</td>
        <td>Bachelor of B.Optometry</td>
        <td>4-Year</td>
        <td>12th-PCB/PCM</td>
        <td>Regular Only </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>
      <tr>
        <td>2.</td>
        <td>Bachelor of physiotherapy</td>
        <td>4-Year</td>
        <td>12th-PCB/PCM</td>
        <td>Regular </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>
         
      
      



  </tbody>
  </table>
</div>


<!--DEGREE Courses END HERE-->



