<div class="container-fluid">

    <img src="assets/banners/news.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> News & Events    </h1>
 
  <div class="container"> 

  <div class="table-responsive table-bordered  ">
      <table class="table-striped">

     <thead>
       <tr >
         <th class="text-center" scope="col">Date</th>
         <th class="text-center" scope="col">News Title </th>
         
         <th class="text-center" scope="col"> News & Events Details</th>
         
       </tr>
     </thead>
     <tbody>

       <tr *ngFor="let obj of dataArr">
         <td>{{obj.date}}</td>
         <td>{{obj.title}}</td>
       
       
         <td style="background: rgb(57, 56, 57); color: greenyellow; "> <marquee behavior="alternate" height="50"> {{obj.description}}  </marquee></td>
         
     
         
       </tr>
      
     </tbody>
   </table>
   </div>
   </div>
  
<!--<div class ="container" id ="mark">
     
           
     
      <ul *ngFor="let obj of dataArr">
        
      <p>
        <li> {{obj.title}}</li>
        <li><span style="color: #ff0000; font-family: open sans, sans-serif; font-size: 14px; font-weight: bold;"></span>{{obj.description}}</li>
        <li>Post Date : {{obj.date}}</li>
      
        </p>
        <hr style="color:#fafafa"/>     
      </ul>
   
     
   
 
</div>-->
