


<div class="container-fluid">

  <img src="assets/banners/gallery.png" alt="first slide" style="display: block; width: 100%;">
  

</div>
 <h1> Photo Gallery </h1>

 
<div class="gallery">

  

  <div class="image-container">

     

      <a *ngFor="let obj of image" href="images/sandwich4.jpg" class="image sandwich">
          <img src="{{imageDirectoryPath}}{{obj.image}}" alt="">
          
      </a>
     
  </div>

</div>



 

























 