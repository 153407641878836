<div class="container-fluid">

    <img src="assets/banners/Legal.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
 
   <h1> Accreditations   </h1>
   <div class ="container">
   <div class="row">
	  <div class="container">
	   <h2>Legal Recognition/Associations/Memberships/Colloborations</h2>
	  <hr/>
	  <div class="col-md-12 side-bg pad">
	  <ul class="list">
	  <li>	Kewal Institute of Technical & Vocational Industrial Training is an ISO 9001:2015 Certified Institution providing Vocational Education.</li>
<li> KIT-VIT is registered under Act of 1882-under the legislation of govt. of India for Vocational Training.</li>
<li> KIT-VIT is registered under the act of 1969, Department of Labour & Employment Govt. of Himachal Pradesh. Reg : No: S-II/311.</li>
<li> KITVIT & NGP Educare Pvt.Ltd. –Both are working for providing vocational training in india which is register under the central Act-1956, Ministry of Corporate Affairs-Govt. India CIN: U80301HP2013PTC000602.</li>
<li> UAN Reg. No: HP08D0000248-Ministry of Micro,Small & Medium Enterprises-Govt. of India.</li>
<li> As per the circular of the Ministry of Home Affairs, Govt. of India New Delhi Notification No 26/4/52 c c. Dated on 20/09/1952 issued in a consultation with the Union Public Service Commission That in case of Degree/ Diploma awarded by Board/ University in India which are incorporated by an Act of central / State Legislature in India. No formal order recognition such Degree/ Diploma need be issued by Govt. Such Degree should be recognized automatically for the purpose of employment.</li>
<li> KIT-VIT offers its own Programs in various areas for achieving Total Literacy in India, as such all its programs are meant for Value Addition, and Entrepreneurship Development.</li>
<li> KIT-VIT Programs are especially aimed to improve employability in Private Sector Organizations in India.</li>
<li> KIT-VIT is an autonomous body, it doesn’t comes under the purview of UGC / AICTE / AIU / DEB Acts.</li>
<li> KIT-VIT follows the syllabi and the nomenclatures, prescribed by University Grants Commission, Government of India for various Programs, designed by its’ Academic Board.</li>
<li> The term “Autonomous body” denotes a self-governing body, independent, or subject to its own laws, regulated by either company law or law on Registered Societies/ Trusts, as the case may be. And so, its own board of directors can specify the rules of business for the body /institution. And that since it is an autonomous body, administration of which is not controlled by any other authority including any State Government / Union Government of India (U.G.I.).</li>
</ul>
</div>
	  </div>
	 </div>
  
   
  <h2> Legal Documents</h2>
   <div class="table-responsive table-bordered  ">
    <table class="table-striped table-hover">

     <thead>
       <tr>
         <th  class="text-center" scope="col">Sr.No.</th>
         <th  class="text-center" scope="col">Name of Documents</th>
         <th  class="text-center" scope="col">Download Link</th>
         
       </tr>

     </thead>
     <tbody>
       <tr *ngFor="let obj of dataArr">
         <td class="text-center">{{obj.id}}</td>
         <td class="text-center">{{obj.title}}</td>
         <td class="text-center"><a href="{{imageDirectoryPath}}/{{obj.path}}"> <button type="button" class="btn btn-dark"><i class="fa fa-download" aria-hidden="true" fa-4x></i>  Download </button> </a></td>
    
     
         
       </tr>
      
     </tbody>
   </table>
   </div>
  </div>