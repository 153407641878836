
 <section class="banner">
  <div class="banner-box">
    <div class="intro-text">
      
         
  <carousel >

  
  <slide>
    <img src="assets/smartcard/1.jpg" alt="first slide" style="display: block; width:100%;">
    <div class="carousel-caption d-lg-block ">
      
    </div>
  </slide>


<slide>
    <img src="assets/smartcard/2.jpg" alt="section slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>

  <slide>
    <img src="assets/smartcard/3.jpg" alt="third slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>

<slide>
    <img src="assets/smartcard/4.jpg" alt="first slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>

  <slide>
    <img src="assets/smartcard/5.jpg" alt="third slide" style="display: block; width: 100%;">
    <div class="carousel-caption d-none d-md-block">
      
    </div>
  </slide>




</carousel>
        </div>
    </div>



