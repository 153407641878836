<br>
 <!--slider boxes starts--

 <div class="slider-box"><p  class = "details"> KeyWorld Online Courses </p>
    <div class="img-box"> <a href = "http://education.keyworldbiz.com/" target="_blank" alt="http://education.keyworldbiz.com/" >
    <img src ="assets/education/icons/9.png"></a>

    </div>
   
    <div class="cart">
        <a href = "http://education.keyworldbiz.com/" target="_blank" alt="http://education.keyworldbiz.com/"class="inside-page__btn inside-page__btn--city">View More </a>
    </div>

    </div>

    <div class="slider-box"><p  class = "details"> Skill-Based Courses </p>
        <div class="img-box"><a routerLink ="/skill-based"routerLinkActivate = "Activate"> 
        <img src ="assets/education/icons/10.png"></a>
        </div>
       
        <div class="cart">
       <a routerLink ="/contactus" routerLinkActivate = "Activate"> View More  </a>
        </div>
        </div> 

        <div class="slider-box"><p  class = "details"> Regular Universities </p>
            <div class="img-box"><a routerLink ="/regular-university"routerLinkActivate = "Activate"> 
            <img src ="assets/education/icons/6.png"></a>
            </div>
           
            <div class="cart">
           <a routerLink ="/regular-university" routerLinkActivate = "Activate"> View More  </a>
            </div>
            </div>
            
            <div class="slider-box"><p  class = "details"> Distance Universities </p>
                <div class="img-box"><a routerLink ="/distance-university"routerLinkActivate = "Activate"> 
                <img src ="assets/education/icons/4.png"></a>
                </div>
               
                <div class="cart">
               <a routerLink ="/distance-university" routerLinkActivate = "Activate"> View More  </a>
                </div>
                </div> 
                
                <div class="slider-box"><p  class = "details"> Hotel & Tourism Courses </p>
                    <div class="img-box"><a routerLink ="/hotel-courses"routerLinkActivate = "Activate"> 
                    <img src ="assets/education/icons/15.png"></a>
                    </div>
                   
                    <div class="cart">
                   <a routerLink ="/hotel-courses" routerLinkActivate = "Activate"> View More  </a>
                    </div>
                    </div>
                
                    <div class="slider-box"><p  class = "details"> Banking & Finance Courses </p>
                        <div class="img-box"><a routerLink ="/finance-courses"routerLinkActivate = "Activate"> 
                        <img src ="assets/education/icons/12.png"></a>
                        </div>
                       
                        <div class="cart">
                       <a routerLink ="/finance-courses" routerLinkActivate = "Activate"> View More  </a>
                        </div>
                        </div>
     
                        <div class="slider-box"><p  class = "details"> Computer & IT Courses </p>
                            <div class="img-box"><a routerLink ="/computer-courses"routerLinkActivate = "Activate"> 
                            <img src ="assets/education/icons/13.png"></a>
                            </div>
                           
                            <div class="cart">
                           <a routerLink ="/computer-courses" routerLinkActivate = "Activate"> View More  </a>
                            </div>
                            </div>
                                 

                            <div class="slider-box"><p  class = "details"> Beauty & Wellness Courses </p>
                                <div class="img-box"><a routerLink ="/beauty-courses"routerLinkActivate = "Activate"> 
                                <img src ="assets/education/icons/14.png"></a>
                                </div>
                               
                                <div class="cart">
                               <a routerLink ="/beauty-courses" routerLinkActivate = "Activate"> View More  </a>
                                </div>
                                </div>
                                
                                <div class="slider-box"><p  class = "details"> Fire & Safety Courses </p>
                                    <div class="img-box"><a routerLink ="/safety-courses"routerLinkActivate = "Activate"> 
                                    <img src ="assets/education/icons/17.png"></a>
                                    </div>
                                   
                                    <div class="cart">
                                   <a routerLink ="/safety-courses" routerLinkActivate = "Activate"> View More  </a>
                                    </div>
                                    </div>
                                    

                                    <div class="slider-box"><p  class = "details"> Teacher Training Courses </p>
                                        <div class="img-box"><a routerLink ="/teacher-courses"routerLinkActivate = "Activate"> 
                                        <img src ="assets/education/icons/18.png"></a>
                                        </div>
                                       
                                        <div class="cart">
                                       <a routerLink ="/teacher-courses" routerLinkActivate = "Activate"> View More  </a>
                                        </div>
                                        </div>
                                        

                                        <div class="slider-box"><p  class = "details"> Retail Sales Courses </p>
                                            <div class="img-box"><a routerLink ="/retail-courses"routerLinkActivate = "Activate"> 
                                            <img src ="assets/education/icons/20.png"></a>
                                            </div>
                                           
                                            <div class="cart">
                                           <a routerLink ="/retail-courses" routerLinkActivate = "Activate"> View More  </a>
                                            </div>
                                            </div>
                                            
                                            <div class="slider-box"><p  class = "details"> Technical Trade Courses </p>
                                                <div class="img-box"><a routerLink ="/engineering-courses"routerLinkActivate = "Activate"> 
                                                <img src ="assets/education/icons/21.png"></a>
                                                </div>
                                               
                                                <div class="cart">
                                               <a routerLink ="/engineering-courses" routerLinkActivate = "Activate"> View More  </a>
                                                </div>
                                                </div>
                                                

                                                <div class="slider-box"><p  class = "details"> Fashion Designing Courses </p>
                                                    <div class="img-box"><a routerLink ="/fashion-courses"routerLinkActivate = "Activate"> 
                                                    <img src ="assets/education/icons/16.png"></a>
                                                    </div>
                                                   
                                                    <div class="cart">
                                                   <a routerLink ="/fashion-courses" routerLinkActivate = "Activate"> View More  </a>
                                                    </div>
                                                    </div>
                                                   
                                                    <div class="slider-box"><p  class = "details"> Paramedical Courses </p>
                                                        <div class="img-box"><a routerLink ="/paramedical-courses"routerLinkActivate = "Activate"> 
                                                        <img src ="assets/education/icons/19.png"></a>
                                                        </div>
                                                       
                                                        <div class="cart">
                                                       <a routerLink ="/paramedical-courses" routerLinkActivate = "Activate"> View More  </a>
                                                        </div>
                                                        </div>
                                                        

                                                        <div class="slider-box"><p  class = "details"> Pharamacy Courses </p>
                                                            <div class="img-box"><a routerLink ="/ayurveda-courses"routerLinkActivate = "Activate"> 
                                                            <img src ="assets/education/icons/22.png"></a>
                                                            </div>
                                                           
                                                            <div class="cart">
                                                           <a routerLink ="/ayurveda-courses" routerLinkActivate = "Activate"> View More  </a>
                                                            </div>
                                                            </div>
                                                            
                                                     <div class="slider-box"><p  class = "details"> Nursing Courses </p>
                                                                <div class="img-box"><a routerLink ="/nursing-courses"routerLinkActivate = "Activate"> 
                                                                <img src ="assets/education/icons/24.png"></a>
                                                                </div>
                                                               
                                                                <div class="cart">
                                                               <a routerLink ="/nursing-courses" routerLinkActivate = "Activate"> View More  </a>
                                                                </div>
                                                                </div>
                                                                

                                                               <div class="slider-box"><p  class = "details"> Law Courses </p>
                                                                    <div class="img-box"><a routerLink ="/law-courses"routerLinkActivate = "Activate"> 
                                                                    <img src ="assets/education/icons/23.png"></a>
                                                                    </div>
                                                                   
                                                                    <div class="cart">
                                                                   <a routerLink ="/law-courses" routerLinkActivate = "Activate"> View More  </a>
                                                                    </div>
                                                                    </div>
                                                                    

 <div class="slider-box"><p  class = "details"> NGP e-Tuition </p>
    <div class="img-box"><a routerLink ="/e-tuition"routerLinkActivate = "Activate"> 
    <img src ="assets/education/icons/3.png"></a>
    </div>
   
    <div class="cart">
   <a routerLink ="/contactus" routerLinkActivate = "Activate"> View More  </a>
    </div>
    </div> 


    <div class="slider-box"><p  class = "details"> Open Schooling Admission </p>
        <div class="img-box"><a routerLink ="/open-school"routerLinkActivate = "Activate"> 
        <img src ="assets/education/icons/2.png"></a>
        </div>
       
        <div class="cart">
       <a routerLink ="/contactus" routerLinkActivate = "Activate"> View More  </a>
        </div>
        </div> 

        <div class="slider-box"><p  class = "details"> Academy Admissions </p>
            <div class="img-box"><a routerLink ="/e-tuition"routerLinkActivate = "Activate"> 
            <img src ="assets/education/icons/7.png"></a>
            </div>
           
            <div class="cart">
           <a routerLink ="/contactus" routerLinkActivate = "Activate"> View More  </a>
            </div>
            </div> 


          
        -->




       
              
                                            
                     
                                

 <h1> Some Important Links </h1>


<div>
    
<app-accordion></app-accordion>

</div>

