import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutRoutingModule } from './layout-routing.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { GalleryComponent } from './gallery/gallery.component';


import { ContactusComponent } from './contactus/contactus.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MissionComponent } from './mission/mission.component';
import { CurriculumComponent } from './curriculum/curriculum.component';
import { ExaminationComponent } from './examination/examination.component';
import { TeachingStaffComponent } from './teaching-staff/teaching-staff.component';
import { OtherStaffComponent } from './other-staff/other-staff.component';
import { LegalComponent } from './legal/legal.component';

import { FessComponent } from './fess/fess.component';

import { LabsComponent } from './labs/labs.component';
import { ToppersComponent } from './toppers/toppers.component';
import { MusicComponent } from './music/music.component';
import { SportsWinnersComponent } from './sports-winners/sports-winners.component';
import { NewsComponent } from './news/news.component';

import { PrincipalMessageComponent } from './principal-message/principal-message.component';
import { PresidentMessageComponent } from './president-message/president-message.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { FrontOfficeComponent } from './front-office/front-office.component';
import { TheoryRoomComponent } from './theory-room/theory-room.component';
import { DownloadComponent } from './download/download.component';

@NgModule({
  declarations: [ HomeComponent, HomeSliderComponent, PageNotFoundComponent,
   AboutusComponent, GalleryComponent,  ContactusComponent, MissionComponent, CurriculumComponent, ExaminationComponent, TeachingStaffComponent, OtherStaffComponent, LegalComponent,  FessComponent,  LabsComponent, ToppersComponent, MusicComponent, SportsWinnersComponent, NewsComponent, PrincipalMessageComponent, PresidentMessageComponent, FrontOfficeComponent, TheoryRoomComponent, DownloadComponent, ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CarouselModule.forRoot(),
    LayoutRoutingModule,
    AlertModule.forRoot()

  ],
  exports:[
    LayoutRoutingModule
  ]
})
export class LayoutModule { }
