<h1> Fashion & Interior Designing Courses </h1>
Career In Fashion & Interior Designing : आज के समय हर व्यक्ति अपने लुक को लेकर इतना सजग हो गया है कि वह हरदम कुछ ऐसा पहनने की चाह रखता है, जो न सिर्फ दूसरों से अलग हो, बल्कि आपको ट्रैंडी भी दिखाएं। आपकी इस चाहत को पूरा करने का काम करते हैं फैशन डिजाइनर। फैशन डिजाइनर अपने अनोखे आइडिया के बूते ही हर साल मार्केट में कुछ नया लेकर आते हैं। अगर आपके पास भी कपड़े की समझ के साथ−साथ कुछ नया व हटकर करने की चाह है तो आप बतौर फैशन डिजाइनर अपना उज्ज्वल भविष्य बना सकते हैं।
<br>
इस क्षेत्र में भविष्य देख रहे छात्रों के लिए ग्रेजुएशन करना आवश्यक नहीं है। आप चाहें तो दसवीं या बारहवीं के बाद भी शॉर्ट टर्म कोर्स करके इस क्षेत्र में कदम रख सकते हैं। आपको इस क्षेत्र में तीन माह से लेकर एक वर्ष तक के कोर्स मिलेंगे। वहीं डिप्लोमा कोर्स एक साल से लेकर चार साल तक होता है। आप अपनी सुविधानुसार इनका चयन कर सकते हैं। कोर्स के दौरान छात्रों को न सिर्फ लेटेस्ट डिजाइन व ट्रैंड की जानकारी दी जाती है। बल्कि सिलाई के बेसिक जैसे कपड़े की कटाई से लेकर उसकी सिलाई तक के बारे में बताया जाता है। आपको पहले अपने डिजाइन को कागज पर उतारना होता है, उसके बाद कपड़े पर।
<br>
इस क्षेत्र में आपकी आमदनी इस बात पर निर्भर करेगी कि आप किसी कंपनी में जॉब करते हैं या फिर अपना खुद का बिजनेस शुरू करते हैं। अगर आप कहीं पर जॉब करते हैं तो शुरूआती दौर में आप 15000 रूपए प्रतिमाह आसानी से कमा सकते हैं, वहीं धीरे−धीरे अनुभव के साथ आपकी आमदनी प्रतिमाह 50000 तक भी आसानी से कमाए जा सकते हैं। इसके अतिरिक्त अगर आप अनुभवी और टैलेंटेड हैं तो आप खुद का बिजनेस शुरू करके लाखों कमा सकते हैं। 

<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT & NGP Educare Gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> Therefore, Join Your Hands With Us As a Student /Trainees, VTPs and Industry Skill Partner For inclusive growth of All.



<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->


<!--TABLE OF Courses START HERE-->

<div class="row div-pad">
  <div class="container">
 <h1 class="headi1">Courses <span>& Eligibility</span></h1>
<h3 style="text-align:center">Certificate Courses</h3>
<div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Certificate in Cutting & Swing</td>
							<td>3-Month</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Certificate in Tailoring</td>
							<td>3-Months</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
                 
            
        					</tbody>
				</table>
			</div>

<!--DIPLOMA Courses START HERE-->

      <h3 style="text-align:center"> Diploma Courses</h3>

      <div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Diploma in Fashion Designing</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular/Distance </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td> Diploma in Interior Designing</td>
							<td>1-Year</td>
							<td>10th Pass</td>
              <td>Regular/Distance </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
           
           

        					</tbody>
				</table>
			</div>


<!--DIPLOMA Courses END HERE-->


<!--Degree Courses START HERE-->

<h3 style="text-align:center"> Degree Courses</h3>

<div class="table-responsive table-bordered  table-hover  ">
  <table class="table-striped">
    <tbody>
      <tr>
        <th>Sr. No.</th>
        <th>Course Name</th>
        <th>Duration</th>
        <th>Eligibility</th>
        <th>Education Mode </th>
        <th scope="col">Action</th>
      </tr>
   
      <tr>
        <td>1.</td>
        <td>B.A in Fashion Designing </td>
        <td>3-Year</td>
        <td>12th pass</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>
      



  </tbody>
  </table>
</div>


<!--DEGREE Courses END HERE-->



