


  
 

<app-header></app-header>


<router-outlet></router-outlet>
 

    
<app-footer ></app-footer>
 <!---*ngif="router.url !== 'dashboard/index'"-->