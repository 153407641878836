<div id="accordion" class="myaccordion">
    
    <!--First Headming-->
  
    <div class="card">
  <div class="card-header" id="headingOne">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
NGP(Next Gen. Path ) EDUCARE PVT. LTD.
          <span class="fa-stack fa-lg">
                <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
      <p><img src="assets/ngpeducare.png" height="70" width="100"/>NGP (Next- Gen. Path ) Educare Pvt. Ltd. Is a private Limited Company Registered: Under the Company Act 1956, Ministry of Corporate Affairs, Govt. Of India . Its registered office is situated in Near Canara Bank, New Bus Stand-Sundernagar-Mandi-H.P. It is an ISO - 9001:2008 Certified Company to promote all education service through Franchisee Centers in India.

The Company has launched many professional or 100 % job oriented Programmes which is very high demands in today. We just believe in inclusive growth of all. Our service Management and regular Communication with our Franchiser is our strength in Market. We assure we will provide you our best service as Compared than other. “So join Hands With US, for Success”.</p>
<ul>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> Register: Under the  act of 1956, Ministry of Corporate Affairs Govt. of India.<br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> Register: UAN  Register Under the Ministry of MSME, Govt. of India.<br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> Register: Trademark Register Under the  Department of  Industrial Policy & Promotion,Ministry of Commerce & Ministry of Commerce & Industry Govt. of India.<br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> An ISO 9001:2015, Certified Company. <br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> Many More Approvals, Affilations, and Registriations..etc.<br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "http://www.ngpeducare.com/accreditation.aspx"> wwww.ngpeducare.com </a>
   
                    
 
          
        </ul>
     </div>
    </div>
    </div>

  <!--2nd Headming-->

  <div class="card">
    <div class="card-header" id="headingTwo">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
    केवल तकनीकी एवं औद्योगिक व्यवसायिक प्रशिक्षण संस्थान
          <span class="fa-stack fa-2x">
               <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
     <p><img src="assets/kitvit.jpg" height="70" width="100"/>  The Indian youth are facing serious problems of unemployment and underemployment. This is due to the phenomenal
rise in population and lack of proper infrastructural facilities to provide good quality education which will earn them a
good living. Although Government is taking massive efforts to improve the scenario, still majority of the population is
not able to get basic infrastructural facilities for their career development. Keeping this in the mind, (Kewal Institute of
Technical & Vocational Industrial Training) has designed courses carefully taking into account the actual need of the
students and the applicability of the subject knowledge in the jobs they are going to undertake in the immediate future.
Vocational Education Programmes has the ability to create corporate ready workforce. Also, it offers end-to-end skill
building programmes focusing on improving the employability quotient of aspirants. Skilled manpower is usually
considered a sine qua non of industrialization. Skilled workers are not born; in fact every worker has the potential of
becoming a skilled worker and this potential can be realized by providing adequate opportunities for Vocational
Education and Training.<br>
At KIT-VIT, Vocational education is imparted through Vocational Training Centers that prepares people for a specific
trade. It directly develops expertise in techniques related to technology, skill and scientific technique to span all aspects
of the trade. Vocational education is classified as using procedural knowledge. Vocational Education prepares people
for specific trades, crafts and careers at various levels from a trade, a craft, technician, or a high professional
practitioner position in careers such as Tourism & Hospitality, Engineering, Accountancy, Nursing, Alternative
Medicine, Fire & Safety, and Teaching etc. Craft vocations are usually based on manual or practical activities and are
traditionally non-academic but related to a specific trade, occupation.<br>
KIT-VIT Launched Vocational Training Programs by two unique Modes-<br>
<b>(1) IITP (Industry Internship Training Programs)
(2) WITP (Work Integrated Training Programs)</b><br>
For Above purposes KIT-VIT collaborate with Industries/Sectors so that Students can get tremendous opportunities for
making a better career in related Industry or Sectors. Anyone can make their future in following booming industry in
India & Abroad. We have following Legal Approvals/Registraions/Approvals/Colloborations etc. : <br><br>
        <ul>
          <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> Register: Under the  Act of 1882, Under the Lagislation of Govt. of India, For Vocational Training. <br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> Register: Under the Act of 1969, Department of Lablour & Employment , Govt. of Himachal Pradesh in India.<br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> KITVIT offers its own Programmes in various areas for achieving Total Literacy in India, as such all its programs are meant for value Addition, and Enterpreneurship Development.<br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> An ISO 9001:2015, Certified organisation. <br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> KITVIT is an Autonomous body, it does not come under the preview of UGC/AICTE/AIU/DEB etc. <br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> KITVIT programs are especially aimed to imporve employability in Private Sector Organizations in India <br>
           
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> Many More Approvals, Affilations, and Registriations..etc.<br>
           <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "http://www.ngpeducare.com/accreditation.aspx"> wwww.ngpeducare.com </a>
  
          
        </ul>
      </div>
    </div>
  </div>
  
    <!--3rd Headming Start -->

  <div class="card">
    <div class="card-header" id="headingThree">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          University Grants Commission(UGC)
          <span class="fa-stack fa-2x">
    <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
        
          </span>
        </button>
      </h2>
    </div>

    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
      <p>The University Grants Commission (UGC) came into existence on 28th December, 1953 and became a statutory Organization of the Government of India by an Act of Parliament in 1956, for the coordination,determination and maintenance of standards of teaching, examination and research in university education. The UGC`s mandate includes: </p>
        <ul>
          <li>Promoting and coordinating university education.</li>
          <li>Determining and maintaining standards of teaching, examination and research in universities. </li>
          <li>Framing regulations on minimum standards of education.</li>
          <li>Monitoring developments in the field of collegiate and university education; disbursing grants to the universities and colleges.</li>
          <li> Serving as a vital link between the Union and state governments and institutions of higher learning.</li>
          <li> Advising the Central and State governments on the measures necessary for improvement of university education.</li>
        </ul>
         <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.ugc.ac.in/"> wwww.ugc.ac.in </a>
  
      </div>
     
    </div>
  </div>

 <!--4th  Headming Start -->

<div class="card">
    <div class="card-header" id="headingFour">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
    Distance Education Bureau(DEB)
          <span class="fa-stack fa-2x">
               <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
     <p> In pursuance of the directions issued by the Ministry of Human Resource Development, Department of Higher Education, Government of India dated 29.12.2012, the regulatory functions with regard to Distance Education programmes in higher education have now been vested with the University Grants Commission. The Distance Education Council which was the erstwhile regulator of Distance Education programmes, has been dissolved and all regulatory functions are being undertaken by the UGC. The UGC is in the process of framing new Regulations for Distance Education. It has, however, been decided that till such time the new Regulations of the UGC are notified, the guidelines of the erstwhile DEC with regard to recognition of ODL institutions shall be implemented for the purposes of grant of permission to institutions for Distance Education programmes.</p>
         <ul>
          <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i>The open and distance learning system in India has emerged as an important mode for providing education to diverse sections of society. Besides, the changing dynamics of the ODL system in the last six decades have been encouraging. With the proliferation in the ICT, the boundaries of classroom or campus are becoming blurred. As it is said, the temporal and spatial boundaries have disappeared ( Kulandaiswamy, 2011). The impressive number of ODL institutions in the country bear testimony to the fact. Single-mode open universities have increased from four in number during the 8th Plan period to 14 in the 10th Plan period. The number of dual mode universities offering programmes through the distance mode (DEIs) has risen to more than 200. This is due to the fact that the growth in the infrastructure for face-to-face instruction is unable to match the educational demands of the ever-increasing number of aspiring students. At present nearly 25% students of higher education in the country are enrolled in the ODL system.

In the last six decades the ODL system has registered a phenoimenal growth in the context of expansion and diversification of higher education. From a single institution in 1962 ( Delhi University) the number of ODL institutions has reached approximately 250 including Central, State, Deemed to be and Private Universities along with many stand alone institutions. <br>
          
          <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.ugc.ac.in/deb/index.html"> https://www.ugc.ac.in/deb/index.html </a>
  
          
        </ul>
      </div>
    </div>
  </div>
  

 <!--5th Headming Start -->

<div class="card">
    <div class="card-header" id="headingFive">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
   AICTE
          <span class="fa-stack fa-2x">
               <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
     <p> In accordance with the provisions of the AICTE Act (1987), for the first five years after its inception in 1988, the Minister for Human Resource Development, the Government of India, was the Chairman of the Council. The first full-time Chairman was appointed on July 2, 1993 and the Council was reconstituted in March 1994 with a term of three years. The Executive Committee was re-constituted on July 7, 1994 and All India Board of Studies and Advisory Boards were constituted in 1994-95. Regional Offices of the Ministry of Human Resource Development, the Government of India, located in Kolkata, Chennai, Kanpur, and Mumbai were transferred to AICTE and the staff working in these offices were also deputed to the Council on foreign service terms w.e.f. October 1, 1995.</p>
         <ul>
             
          <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.aicte-india.org/"> https://www.aicte-india.org/ </a>
  
          
        </ul>
      </div>
    </div>
  </div>
  
  <!--6th Headming Start -->

<div class="card">
    <div class="card-header" id="headingSix">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
   Bar Council Of India
          <span class="fa-stack fa-2x">
               <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
      <div class="card-body">
     <p> In accordance with the provisions of the AICTE Act (1987), for the first five years after its inception in 1988, the Minister for Human Resource Development, the Government of India, was the Chairman of the Council. The first full-time Chairman was appointed on July 2, 1993 and the Council was reconstituted in March 1994 with a term of three years. The Executive Committee was re-constituted on July 7, 1994 and All India Board of Studies and Advisory Boards were constituted in 1994-95. Regional Offices of the Ministry of Human Resource Development, the Government of India, located in Kolkata, Chennai, Kanpur, and Mumbai were transferred to AICTE and the staff working in these offices were also deputed to the Council on foreign service terms w.e.f. October 1, 1995.</p>
         <ul>
             
          <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "http://www.barcouncilofindia.org/"> http://www.barcouncilofindia.org/ </a>
  
          
        </ul>
      </div>
    </div>
  </div>

<!--7th Headming Start -->

<div class="card">
    <div class="card-header" id="headingSeven">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
   Pharmacy Council Of India
          <span class="fa-stack fa-2x">
               <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>
    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
      <div class="card-body">
     <p> The Pharmacy education and profession in India upto graduate level is regulated by the PCI, a statutory body governed by the provisions of the Pharmacy Act, 1948 passed by the Parliament.

➤ The Pharmacy Act 1948 was enacted on 4.3.48 with the following preamble- "An Act to regulate the profession of pharmacy. Whereas it is expedient to make better provision for the regulation of the profession and practice of pharmacy and for that purpose to constitute Pharmacy Councils".
➤ The PCI was constituted on 9.8.49 under section 3 of the Pharmacy Act.
</p>
         <ul>
             
          <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.pci.nic.in/"> https://www.pci.nic.in/</a>

  
          
        </ul>
      </div>
    </div>
  </div>


<!--8th Headming Start -->

<div class="card">
    <div class="card-header" id="headingEight">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
   Dental Council Of India
          <span class="fa-stack fa-2x">
               <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>
    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
      <div class="card-body">
     <p> Dental Council of India is a Statutory Body incorporated under an Act of Parliament viz. The Dentists Act, 1948 (XVI of 1948) to regulate the Dental Education and the profession of Dentistry throughout India and it is financed by the Govt. of India in the Ministry of Health & Family Welfare (Department of Health) through Grant-in-aid. The General Body of the Dental Council of India representing various State Governments, Universities, Dental Colleges, Central Government, etc.




</p>
         <ul>
             
          <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://dciindia.gov.in/"> https://dciindia.gov.in/</a>

  
          
        </ul>
      </div>
    </div>
  </div>



<!--9th Headming Start -->

<div class="card">
    <div class="card-header" id="headingNine">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
   AIU
          <span class="fa-stack fa-2x">
               <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>
    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#accordion">
      <div class="card-body">
     <p> The formation of the Inter-University Board (IUB) of India as an Apex Inter-University Organization on March 23, 1925, in a meeting of Vice Chancellors/their representatives at Bombay University was the culmination of the need to bring together all the universities in India on a common platform through a coordinating body, to protect the interest of  the students as well as the universities. The objective was to promote university activities, especially  by  way  of  sharing  information  and  increasing co-operation  in  the field of  education, culture, sports and allied areas. Prior to this, having such an organization in India was recommended by the Sadler Commission in 1919 followed by a resolution, made in the Conference of Vice Chancellors of  the Indian Universities convened at Shimla in 1924. 
<br>
The Inter-University Board acquired a legal status with its registration as a Society under the Societies Registration Act, 1860, on September 29, 1967 and was renamed as Association of  Indian  Universities  (AIU)  in  1973.  Since  its  inception  AIU  is  actively engaged in the growth and development of  Higher Education. The membership of AIU includes all types of universities e.g. Conventional Universities, Open Universities, Deemed to be Universities, State Universities, Central Universities, Private Universities and Institutes of National Importance. In addition to Indian Universities, 13 Universities/Institutes from Bangladesh, Bhutan, Republic of Kazakhstan, Malyasia, Mauritius, Nepal, Thailand, United Arab Emirates and United Kingdom are its Associate Members.


</p>
         <ul>
             
          <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "https://www.aiu.ac.in/index.php"> https://www.aiu.ac.in/index.php</a>

  
          
        </ul>
      </div>
    </div>
  </div>


<!--10th Headming Start -->

<div class="card">
    <div class="card-header" id="headingTen">
      <h2 class="mb-0">
        <button class="d-flex align-items-center justify-content-between btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
        NAAC
          <span class="fa-stack fa-2x">
               <i class="fa fa-angle-double-down fa-2x" aria-hidden="true"></i>
          </span>
        </button>
      </h2>
    </div>
    <div id="collapseTen" class="collapse" aria-labelledby="headingTen" data-parent="#accordion">
      <div class="card-body">
     <p> India has one of the largest and diverse education systems in the world. Privatization, widespread expansion, increased autonomy and introduction of Programmes in new and emerging areas have improved access to higher education. At the same time, it has also led to widespread concern on the quality and relevance of the higher education. To address these concerns, the National Policy on Education (NPE, 1986) and the Programme of Action (PoA, 1992) spelt out strategic plans for the policies, advocated the establishment of an independent National accreditation agency. Consequently, the National Assessment and Accreditation Council (NAAC) was established in 1994 as an autonomous institution of the University Grants Commission (UGC) with its Head Quarter in Bengaluru. The mandate of NAAC as reflected in its vision statement is in making quality assurance an integral part of the functioning of Higher Education Institutions (HEIs).
<br>
The NAAC functions through its General Council (GC) and Executive Committee (EC) comprising educational administrators, policy makers and senior academicians from a cross-section of Indian higher education system. The Chairperson of the UGC is the President of the GC of the NAAC, the Chairperson of the EC is an eminent academician nominated by the President of GC (NAAC). The Director is the academic and administrative head of NAAC and is the member-secretary of both the GC and the EC. In addition to the statutory bodies that steer its policies and core staff to support its activities NAAC is advised by the advisory and consultative committees constituted from time to time.

</p>
         <ul>
             
          <i class="fa fa-check-square-o fa-2x" aria-hidden="true"></i> For More Information Click on following link :  <i class="fa fa-search fa-2x" aria-hidden="true"></i> &nbsp; <a href = "http://www.naac.gov.in/"> http://www.naac.gov.in/</a>

  
          
        </ul>
        
      </div>
    </div>
  </div>




</div>