import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edu-slider',
  templateUrl: './edu-slider.component.html',
  styleUrls: ['./edu-slider.component.css']
})
export class EduSliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
