
<h1> Arts & Science Courses </h1>
<b>Career in Arts & Science : </b> 
As a field of study, arts (or humanities) draws inspiration from history, language, cultures, societal norms, philosophy, visual arts, music, and other intellectual aspects of life. Students pursuing arts subjects are taught and trained to harness their exploratory, analytical, and critical faculties. Thus, students who complete their higher secondary studies in arts find it quite comfortable to prepare for the Indian Civil Services examinations. 
<br>
Some of the most promising subjects taught under humanities include History, Geography, Sociology, Economics, Mass Communication, Law, Psychology, Political Science, Linguistics, and Language studies (English, Hindi, Sanskrit, etc.). One of the best things about 12th arts is that there are plenty of career options after 12th arts. One has to know the list of all career options after 12th arts to select the right one.
<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT & NGP Educare Gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> Therefore, Join Your Hands With Us As a Student /Trainees, VTPs and Industry Skill Partner For inclusive growth of All.


<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->

<!--Degree Courses START HERE-->
<h1 class="headi1">Courses <span>& Eligibility</span></h1>
<h3 style="text-align:center"> Degree Courses</h3>

<div class="table-responsive table-bordered  table-hover  ">
  <table class="table-striped">
    <tbody>
      <tr>
        <th>Sr. No.</th>
        <th>Course Name</th>
        <th>Duration</th>
        <th>Eligibility</th>
        <th>Education Mode </th>
        <th scope="col">Action</th>
      </tr>
      <tr>
        <td>1.</td>
        <td> B.A.(Any Subjects) </td>
        <td>3-Year</td>
        <td>12th-Pass</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>
      <tr>
        <td>2.</td>
        <td>B.Sc.(Any-Subjects) </td>
        <td>3-Year</td>
        <td>12th-pcm/pcb</td>
        <td>Regular only </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>
      <tr>
        <td>3.</td>
        <td>M.Sc.(Any-Subjects) </td>
        <td>2-Year</td>
        <td>B.Sc.</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>  

      <tr>
        <td>4.</td>
        <td>M.A.(Any-Subjects) </td>
        <td>2-Year</td>
        <td>BA</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>  

  </tbody>
  </table>
</div>


<!--DEGREE Courses END HERE-->


