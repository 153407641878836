import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { MyserviceService } from 'src/app/Login Services';
import { Account } from 'src/app/Login Models';


@Component({
  selector: 'app-skill-based',
  templateUrl: './skill-based.component.html',
  styleUrls: ['./skill-based.component.css']
})
export class SkillBasedComponent implements OnInit {

  accounts = new Account
  myserviceService: any;
  successMessage: string | undefined;
 


  constructor(private myservice : MyserviceService , http : HttpClient) { }
    myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    email: new FormControl('', ([Validators.required, Validators.email])),
    mobile_no: new FormControl('', [Validators.required,Validators.maxLength(10)]),
    course_name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    message: new FormControl('', [Validators.required])
  });
    
 
      
  get f(){
    return this.myForm.controls;
  }
     
  onFileChange(event: { target: { files: string | any[]; }; }) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
    }
  }
  ngOnInit(): void {
  }
  
  
  submit() {
    console.log(this.myForm.value);
    

    if (this.myForm.valid) {
      this.myservice.eduData(this.myForm.value)
        .subscribe(
          _data => this.successMessage = 'Thankyou!! for Contact us!! Your message or query has been sent  Successfully ',
          _error => this.successMessage = 'Some error is comming Kindly Contact us by Call or whats app !'
          
        );
        this.myForm.reset();
    }
  }
}