import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-distance-university',
  templateUrl: './distance-university.component.html',
  styleUrls: ['./distance-university.component.css']
})
export class DistanceUniversityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
