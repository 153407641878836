import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EdiscountcardComponent } from './ediscountcard/ediscountcard.component';
import { EvisitingcardComponent } from './evisitingcard/evisitingcard.component';
import { KeyworldSmartcardComponent } from './keyworld-smartcard/keyworld-smartcard.component';
import { SmartcardSliderComponent } from './smartcard-slider/smartcard-slider.component';
import { SmartpageComponent } from './smartpage/smartpage.component';

const routes: Routes = [
  {path : '', redirectTo: '/keyworldsmartcard',pathMatch: 'full'},
  {path: 'smartcard-slider', component: SmartcardSliderComponent},

  {path: '', component: SmartpageComponent, children:[

  {path: 'keyworldsmartcard', component: KeyworldSmartcardComponent },
  {path: 'keyworldvisitingcard', component: EvisitingcardComponent},
  {path: 'discountcard', component: EdiscountcardComponent},





  
]}
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ESmartcardRoutingModule { }
