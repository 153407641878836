<div class="container-fluid">

    <img src="assets/banners/download.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Download documents  </h1>
   <div class ="container">

   <div class="table-responsive table-bordered  ">
    <table class="table-striped table-hover">

     <thead>
       <tr>
         <th  class="text-center" scope="col">Sr.No.</th>
         <th  class="text-center" scope="col">Name of Documents</th>
         <th  class="text-center" scope="col">Download Link</th>
         
       </tr>

     </thead>
     <tbody>
       <tr >
         <td class="text-center">testing</td>
         <td class="text-center">testing</td>
         <td class="text-center"><a href="#"> <button type="button" class="btn btn-dark"><i class="fa fa-download" aria-hidden="true" fa-4x></i>  Download </button> </a></td>
    
     
         
       </tr>
      
     </tbody>
   </table>
   </div>
  </div>