import { Component } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers : [AuthService]
})
export class AppComponent {
  title = 'keyworldbiz';
 
 constructor(public router: Router){}

}