<div class="container-fluid">

  <img src="assets/6.jpg" alt="first slide" style="display: block; width: 100%;">
  
</div>
<section class="cmspage mtb-40">
  <div class="container">
    <div class="error-404">
      <h1>404</h1>
      <h2>There is nothing here!</h2>
      <p>Sorry, the page you were looking for in this blog does not exist.</p>
      <button (click)="gotoHome()" class="btn btn-danger">Go to Home</button>
    </div>
  </div>
</section>