
<!-- banner designs -->
<!--- shopping image slider -->

<div class="container-fluid">

<app-smartcard-slider> </app-smartcard-slider>
 
  
</div>

<h1> KeyWorld smart Card  </h1>

<!-- smartpage page designs -->

<div class="wrapper">
 <article class="main">
 
 
<div >



<router-outlet></router-outlet>
  
</div>


 

  
  



 </article>



 
<!-- The sidebar of KeyWorld Shopping -->

<div class="sidebar">
  <a class="active" a routerLink="keyworldsmartcard" routerLinkActivate="active">KeyWorld Smart Card </a>
  <a routerLink="#" routerLinkActivate="active">KeyWorld Visiting Card </a>
  <a routerLink="#" routerLinkActivate="active">KeyWorld Shopping Card </a>
  <a routerLink="#" routerLinkActivate="active">KeyWorld Discount Card </a>
  <a routerLink="#" routerLinkActivate="active">KeyWorld Employee Card </a>
  <a routerLink="#" routerLinkActivate="active">KeyWorld Pay Card </a>
  <a routerLink="#" routerLinkActivate="active">KeyWorld Health Card </a>
  <a routerLink="#" routerLinkActivate="active">KeyWorld Student Card </a>
  
 
  
  
  
  
  
  
  
  
</div>
</div>

  <!-- brand slider -->



 <h1> Advertisement </h1>

 <div class="wrapper" id ="container3"> 

		
								
<carousel [showIndicators]="showIndicator" [itemsPerSlide]="itemsPerSlide"
          [singleSlideOffset]="singleSlideOffset"
          [noWrap]="noWrap"
    
          [startFromIndex]="3"
      
          (slideRangeChange)="onSlideRangeChange($event)">
 <slide *ngFor="let slide of slides; let index=index" class="mx-auto">
    	<div class="img-box" id ="advt"> <img [src]="slide.image" alt="image slide" style="display: block; width: 100%;"class="img-responsive center-block">
    <div class="carousel-caption"></div>
      
    </div>
  </slide>
</carousel>
 

</div>

<!-- brand partner marquee -->

<h1> Brand Partner </h1>

 <div class="container"> 

<marquee behavior="scroll" scrollamount="3" direction="left" onmouseover="stop()"
			onmouseout="start()" style="padding: 5px;">
		
				
			<img src="assets/softwares/brand/keyworld.png" width="200" height="100" alt="KeyWorld" style="padding:5px"/>
      <img src="assets/softwares/brand/ngpeducare.png" width="150" height="100" alt="NGP Educare" style="padding:5px"/>
      
		
		

				
			

			 </marquee>

 </div>
