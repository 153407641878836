import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-staff',
  templateUrl: './other-staff.component.html',
  styleUrls: ['./other-staff.component.css']
})
export class OtherStaffComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
