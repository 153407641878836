<h1> Beauty & Wellness Courses </h1>
<b>Career In Beauty & Wellness  Sector : </b> आज के समय में स्टाइलिश और खूबसूरत कौन नही दिखना चाहता है। लेकिन क्या आप जानते है लोगों के स्टाइलिश दिखने के इसी क्रेज ने कॉस्मेटोलॉजी इंडस्ट्री में जॉब के भरपूर अवसर पैदा किए है। आज हर बड़े से लेकर छोटे शहर में बढ़ते ब्यूटी सैलून, स्पा और कॉस्मेटिक क्लिनिक्स की वजह से इस क्षेत्र को युवा बतौर करियर के रूप में अपना रहे है। अगर आप भी सुंदरता के कद्रदान है और लोगों को खूबसूरत बनाना आपको अच्छा लगता है तो आपके लिए कॉस्मेटोलॉजी का करियर बेहतर साबित हो सकता है।
<br> <br>
<b>रोजगार के मौके- अगर आप कॉस्मेटोलॉजी में कोई प्रोफेशनल कोर्स करते है तो आपको कई कॉस्मेटिक उत्पाद निर्माता कंपनियों में जॉब मिल सकती है। इसके अलावा कॉस्मेटोलॉजी के कई क्षेत्रों जैसे हेयरस्टाइलिंग, स्किन केयर, कॉस्मेटिक्स, मेनीक्योर-पेडीक्योर और इलेक्ट्रोलॉजी से संबंधित क्षेत्रों में जॉब उपलब्ध है। आप चाहे तो किसी ब्यूटी सैलून, स्पा, रिसॉर्ट और होटल आदि में भी एक कॉस्मेटोलॉजी के रूप में रोजगार पा सकते है। इसके अलावा एक प्रोफेशनल और एक्सपीरियंस कॉस्मेटोलॉजिस्ट को फैशन, फिल्म, टेलीविजन और थियटर में भी जॉब मिल सकता है। अगर आप इस फील्ड में टीचिंग करना चाहते है तो कई कॉस्मेटोलॉजी इंस्टीट्यूट में आप एक फैकल्टी के रूप में काम कर सकते है। 

<br><br>
<b> सैलरी पैकेज- </b> अगर आप एक कॉस्मेटोलॉजिस्ट के रूप में किसी सैलून से नौकरी की शुरूआत करते है तो आप शुरूआती तौर पर 10,000 से 30,000 हजार रूपये महीने कमा सकते है। इसके अलावा थोड़े से एक्सपीरियंस के बाद आप 1500 से 4000 रूपये हर दिन कमा सकते है।


<br>
<br>

<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT & NGP Educare Gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> Therefore, Join Your Hands With Us As a Student /Trainees, VTPs and Industry Skill Partner For inclusive growth of All.




<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->


<!--TABLE OF Courses START HERE-->

<div class="row div-pad">
  <div class="container">
 <h1 class="headi1">Courses <span>& Eligibility</span></h1>
<h3 style="text-align:center">Certificate Courses</h3>
<div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Certificate in Beauty Care</td>
							<td>3-Month</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Certificate in Beauty & Wellnessr</td>
							<td>3-Months</td>
							<td>10th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
                 
            
        					</tbody>
				</table>
			</div>

<!--DIPLOMA Courses START HERE-->

      <h3 style="text-align:center"> Diploma Courses</h3>

      <div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Diploma in Beautician & Cosmetics</td>
							<td>1-Year</td>
							<td>12th Pass</td>
              <td>Regular only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td> Certified in Beauty & Therapy</td>
							<td>1-Year</td>
							<td>12th Pass</td>
              <td>Regular only </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
           
            <tr>
							<td>3.</td>
							<td>Certified Spa Therapy Executive</td>
							<td>1-Year</td>
							<td>12th Pass</td>
              <td>Regular </td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
           
           


        					</tbody>
				</table>
			</div>


<!--DIPLOMA Courses END HERE-->



