import { Component, OnInit } from '@angular/core';
import {CarouselConfig} from 'ngx-bootstrap/carousel'
@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 5000, noPause: true, showIndicators: true,
      pauseOnFocus:false,   } }
  ]
})
export class HomeSliderComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }

}
