import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ESmartcardRoutingModule } from './e-smartcard-routing.module';
import { SmartpageComponent } from './smartpage/smartpage.component';
import { EvisitingcardComponent } from './evisitingcard/evisitingcard.component';
import { EdiscountcardComponent } from './ediscountcard/ediscountcard.component';
import { KeyworldSmartcardComponent } from './keyworld-smartcard/keyworld-smartcard.component';
import { SmartcardSliderComponent } from './smartcard-slider/smartcard-slider.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';


@NgModule({
  declarations: [SmartpageComponent, EvisitingcardComponent, EdiscountcardComponent, KeyworldSmartcardComponent, SmartcardSliderComponent],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CarouselModule.forRoot(),
    AccordionModule.forRoot(),
  
    BrowserAnimationsModule,

    ESmartcardRoutingModule
  ],
  exports :[
    ESmartcardRoutingModule

  ]
})
export class ESmartcardModule { }
