import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toppers',
  templateUrl: './toppers.component.html',
  styleUrls: ['./toppers.component.css']
})
export class ToppersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
