import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sports-winners',
  templateUrl: './sports-winners.component.html',
  styleUrls: ['./sports-winners.component.css']
})
export class SportsWinnersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
