<div class="container-fluid">

    <img src="assets/banners/toppers.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Our School Toppers    </h1>
   <h2>List of Our School Toppers</h2>
   <div class="container">
    <div class="row">

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="our-team">
          <div class="picture">
            <img class="img1" src="assets/icons/2.png" style="width:100%;height:100%;"  /><br>
          </div>
          <div class="team-content">
            <h3 class="name"> Student Name </h3>
            <h4 class="title">Class:........</h4>
            <h4 class="title">Session:......</h4>
          </div>
          <ul class="social">
            <li><a href="#"> KAVERI PUBLIC SCHOOL</a></li>
            
          </ul>
        </div>
      </div>


      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="our-team">
          <div class="picture">
            <img class="img1" src="assets/icons/2.png" style="width:100%;height:100%;"  /><br>
          </div>
          <div class="team-content">
            <h3 class="name"> Student Name </h3>
            <h4 class="title">Class:........</h4>
            <h4 class="title">Session:......</h4>
          </div>
          <ul class="social">
            <li><a href="#"> KAVERI PUBLIC SCHOOL</a></li>
            
          </ul>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="our-team">
          <div class="picture">
            <img class="img1" src="assets/icons/2.png" style="width:100%;height:100%;"  /><br>
          </div>
          <div class="team-content">
            <h3 class="name"> Student Name </h3>
            <h4 class="title">Class:........</h4>
            <h4 class="title">Session:......</h4>
          </div>
          <ul class="social">
            <li><a href="#"> KAVERI PUBLIC SCHOOL</a></li>
            
          </ul>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="our-team">
          <div class="picture">
            <img class="img1" src="assets/icons/2.png" style="width:100%;height:100%;"  /><br>
          </div>
          <div class="team-content">
            <h3 class="name"> Student Name </h3>
            <h4 class="title">Class:........</h4>
            <h4 class="title">Session:......</h4>
          </div>
          <ul class="social">
            <li><a href="#"> KAVERI PUBLIC SCHOOL</a></li>
            
          </ul>
        </div>
      </div>

   


    </div>
  </div>