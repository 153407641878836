import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeSliderComponent } from './layout/home-slider/home-slider.component';






const routes: Routes = [
 
  { path: 'smartpage', loadChildren: () => import(`./e-smartcard/e-smartcard.module`).then(m => m.ESmartcardModule) },
  { path: 'education',loadChildren: () => import(`./e-education/e-education.module`) .then(m =>m.EEducationModule) },
];


[
  //{path: '',  pathMatch: 'full', component: HomeComponent},
  {path: 'home-slider', component: HomeSliderComponent},
  //{path: 'navbar', component: NavbarComponent},
  //{path: 'header', component: HeaderComponent},
  //{path: 'footer', component: FooterComponent},
  //{path: '**', component: PageNotFoundComponent},
 // {path: 'aboutus', component: AboutusComponent},
  //{path: 'gallery', component: GalleryComponent},
  //{path: 'download', component: DownloadComponent},
  //{path: 'terms', component: TermsComponent},
  //{path: 'contactus',component: ContactusComponent},
  //{path: 'smartpage', component: SmartpageComponent, children:[
     
    //   {path: 'evisitingcard', component: EvisitingcardComponent},
      // {path: 'ediscountcard', component: EdiscountcardComponent},
       
   // ] 
 // },

  
  //{path: 'education', component : EducationComponent},
  //{path: 'shopping' , component : ShoppingComponent},
  //{path: 'software', component : SoftwareComponent},
  //{path: 'staffing', component : StaffingComponent},
  //{path: 'booking', component : BookingComponent},
  //{path: 'rent', component : RentComponent},
  //{path: 'homeservices', component : HomeservicesComponent},
  //{path: 'ads', component : AdsComponent},
  //{path: 'financialservices',component : FinancialservicesComponent},
  //{path: 'legalservices', component : LegalservicesComponent},
  //{path: 'franchisee', component : FranchiseeComponent},
  
 
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
