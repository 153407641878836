<div class="container-fluid">

    <img src="assets/banners/library.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Theory Rooms     </h1>
   <div class="container">
    <div class="row">

      <div class="col-sm-3">
        <h2>Theory Room</h2>
        <img class="img2" src="assets/banners/theory.jpg" style="width:100%;height:auto;"  /><br>
        
</div>
      <div class="col-sm-7">

   
        <p> 
          These Class rooms, therefore, make classroom teaching and learning an enjoyable and fruitful experience for the students as well as faculty members. Display boards have been put up in all the Class rooms, which serve as platforms for encouraging creativity and increasing awareness among the students.
        </p>
         
         
        <img class="img1" src="assets/banners/theoryroom.jpg" style="width:100%;height:50%;"  /><br>
    <p>We have well infrastructure for students so that students can feel always better than others.</p>
    <br><br>
      </div>



    <div class="col-sm-2 sidenav">
      <div class="sidebar">
        <a class="active" routerLink="/frontoffice" routerLinkActivate="Activate">Reception Area </a>
        <a routerLink="/theoryroom" routerLinkActivate = "Activate"> Theory-Rooms</a>
                <a routerLink="/labs" routerLinkActivate = "Activate"> Computer-Labs</a>
       
              
        
        
        
        
        
        
        
      </div>
     
</div>
