<h1> Skill-Based & WITP Courses </h1>
<b>Work Integrated Training Programme: </b> is where students learn the skill by engaging in on-the –job training at real shop floor of the industry/Company. For effective delivery of this model, we will leveraging on the expertise of a partner that will provide classroom-based training and will be known as VTPs (Vocational Training Partners). All the VTPs will enroll the students for Internships but before sending as an Internship Trainee the VTPs will provide classroom theoretical training for students. 
Where students will get their industry training/Job training –these will called KIT-VIT/NGP Educare Industry Skill Partner. All Industry/Sector Skill Partner Will responsible for providing the live or actually training to the students or working trainee/staff etc. 
Aims of KIT-VIT WITP Mode :
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i>The aim of our WITP is to provide the trainee the opportunity to enhance their skills and gain workplace-based industry experience as a part of their courses. For the trainee, the experience is valuable as training is built around the context of work, enabling them to learn by working and observing.
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> This model also allows for design of the programme with an ‘Earn while you Learn ‘feature. This feature enables the trainee to earn a stipend during on-the-job duration of the course of training to support their simultaneous vocational Training.
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT through its VTPs, will provide the requisite structured classroom sessions while inducting them into the work settings. The WITP programme would further enable students to perform better in the highly technically advanced world by providing them with a platform to learn the duties and responsibilities of various employees while they are student-trainees at the facility.
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>
Therefore, join hands with us as a Student /Trainees, VTPs and Industry Skill Partner for inclusive growth of all.

For More  : www.ngpeducare.com

<!--Modal of Apply Now-->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
		  <div>
            <mat-form-field appearance= "fill" style="width:100%;">
            
            <mat-label> <b> Mob No :</b> </mat-label> 
            <input type="text"  formControlName ="mobile_no" matInput placeholder=" Enter Your Mob No :">
                      
            </mat-form-field>
                     
            </div>  
        
		  <div>
			<mat-form-field appearance="fill"style="width:100%;">
			
			<mat-label> <b> Course  Name : </b> </mat-label> 
			<input type="text" formControlName="course_name" matInput placeholder="Enter Your Course Name: ">
					  
			</mat-form-field>
					 
			</div>   
		  

        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
			
			<alert type="success">
				<strong>{{successMessage}} </strong>
				</alert> 
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--Modal of Apply Now end here-->


<!--TABLE START FROM HERE-->

<div class="row div-pad">
  <div class="container">
 <h1 class="headi1">Courses <span>& Eligibility</span></h1>
<h3 style="text-align:center">Department of Engineering</h3>
<div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Diploma in Mechanical</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Diploma in Electrical</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Diploma in Automobile</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
						<tr>
							<td>4.</td>
							<td>Diploma in Civil</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
						<tr>
							<td>5.</td>
							<td>Diploma in Tool & Die Maker</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
						<tr>
							<td>6.</td>
							<td>Diploma in CNC</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
						<tr>
							<td>7.</td>
							<td>Diploma in Fitter Trade</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
						<tr>
							<td>8.</td>
							<td>Diploma in Turner Trade</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
						<tr>
							<td>9.</td>
							<td>Diploma in Welder Trade</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
						<tr>
							<td>10.</td>
							<td>Diploma in Refrigeration & Air Condition</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
						<tr>
							<td>11.</td>
							<td>Diploma in Civil Draughtsman</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
						<tr>
							<td>12.</td>
							<td>Diploma in Land Surveying</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>

						</tr>
					</tbody>
				</table>
			</div>

			<h3 style="text-align: center;">Department of Engineering - Trade Certificates</h3>
			<div class="table-responsive table-bordered  table-hover">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th> Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Trade Certificate in Mechanical Fitter</td>
							<td>Two-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Trade Certificate in Welding</td>
							<td>Two-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Trade Certificate in Electrical</td>
							<td>Two-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>4.</td>
							<td>Trade Certificate in Civil Draughtsman</td>
							<td>Two-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
					</tbody>
				</table>
			</div>

			<h3 style="text-align: center;">Department of Paramedical Technologies</h3>
			<div class="table-responsive table-hover">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th> Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Diploma in Nursing Assistant</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Diploma in Medical lab Technician</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Diploma in Ward Technician</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>4.</td>
							<td>Certificate in Physiotherapy</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>5.</td>
							<td>Diploma in Operation Theater Technician</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>6.</td>
							<td>Diploma in Hospital Management</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>7.</td>
							<td>Diploma in Pharmacy Assistant</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"> Apply Now </button></td>
						</tr>
						<tr>
							<td>8.</td>
							<td>Diploma in Dresser</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>9.</td>
							<td>Diploma in Assistant  Nursing & Mid Wafers</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>10.</td>
							<td>Certificate in Blood Collection Assistant</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>11.</td>
							<td>Certificate in EGC Operator</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>12.</td>
							<td>Certificate in X-Ray Operator</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>13.</td>
							<td>Certificate in First Aid</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
					</tbody>
				</table>
			</div>

			<h3 style="text-align: center;">Department of Management</h3>
			<div class="table-responsive table-hover">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th> Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Construction & Infrastructure</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Telecommunication & Networking</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Power Distribution & Energy Management</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>4.</td>
							<td>Production & Material Management</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>5.</td>
							<td>Retail Management</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>6.</td>
							<td>Logistic & Supply Chain Management</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>7.</td>
							<td>Human Resource Management</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>8.</td>
							<td>Marketing  Management</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>9.</td>
							<td>Finance Management</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>10.</td>
							<td>School management</td>
							<td>Six-Month</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
					</tbody>
				</table>
			</div>

			<h3 style="text-align: center;">Department of Hotel Management</h3>
			<div class="table-responsive table-hover">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th> Action</th>
						</tr>
						<tr>
							<td>1.</td>

							<td>Diploma in Hotel Management</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Diploma in Catering Technology</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Diploma in Hotel Management & Catering Technology</td>
							<td>Two-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
					</tbody>
				</table>
			</div>

			<h3 style="text-align: center;">Department of Fire & Safety Management</h3>
			<div class="table-responsive table-hover">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th> Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Diploma in Fire & Safety</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Diploma in Industrial Safety</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
					</tbody>
				</table>
			</div>

			<h3 style="text-align: center;">Department of Computer Science & IT</h3>
			<div class="table-responsive table-hover">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th> Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Certificate in DTP Operator</td>
							<td>Six-Month</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Diploma in Computer Hardware & Networking</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"> Apply Now </button></td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Certificate in Accounting Software</td>
							<td>Six-Month</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>4.</td>
							<td>Diploma in Office Assistant Cum Computer Operator</td>
							<td>Six-Month</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>5.</td>
							<td>Certificate in MS-OFFICE</td>
							<td>Six-Month</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>6.</td>
							<td>Diploma in Computer Application</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>7.</td>
							<td>Diploma in Computer Teacher Training</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>8.</td>
							<td>PG Diploma in Computer Application</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>9.</td>
							<td>Diploma in E-Publishing</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
					</tbody>
				</table>
			</div>

			<h3 style="text-align: center;background:red;">Department of Teacher Education</h3>
			<div class="table-responsive table-hover">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th> Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Montessori Teacher Training</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Diploma in Nursery Teacher  Training</td>
							<td>One-Year</td>
							<td>10th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>3.</td>
							<td>advanced Diploma in Nursery Teacher Training</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>4.</td>
							<td>Diploma in Computer Teacher Training</td>
							<td>One-Year</td>
							<td>12th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
					</tbody>
				</table>
			</div>

		
			<h3 style="text-align: center;">Department of Skill Development Courses</h3>
			<div class="table-responsive table-hover">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th> Action</th>
						</tr>
						<tr>
							<td>1.</td>

							<td>Certificate in Carpenter</td>
							<td>Six-Month</td>
							<td>8th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>2.</td>
							<td>Certificate in Plumbing</td>
							<td>Six-Month</td>
							<td>8th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>3.</td>
							<td>Certificate in Cutting Tailoring</td>
							<td>Six-Month</td>
							<td>8th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>4.</td>
							<td>Certificate in House Wiring & Electrical Appliance Repairing</td>
							<td>Six-Month</td>
							<td>8th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>5.</td>
							<td>Certificate in Motor & Transformer Rewinding</td>
							<td>Six-Month</td>
							<td>8th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>6.</td>
							<td>Certificate in Mobile Phone Repairing</td>
							<td>Six-Month</td>
							<td>8th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
						<tr>
							<td>7.</td>
							<td>Certificate in House keeping</td>
							<td>Six-Month</td>
							<td>8th Pass</td>
              <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
						</tr>
					</tbody>
				</table>
			</div>

		