import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';


import { data, event } from 'jquery';
import { MyserviceService } from 'src/app/Login Services';


@Component({
  selector: 'app-fess',
  templateUrl: './fess.component.html',
  styleUrls: ['./fess.component.css']
})
export class FessComponent implements OnInit {

  image:any;
  dataArr:any;
  data: any;
  //imageDirectoryPath:any ='http://127.0.0.1:8080/public/schooladmin/public/images/';
  constructor(
      private myservice:MyserviceService
  ) { }

  ngOnInit() {
    this.FeesGet();

  }
    FeesGet()
  {
    this.myservice.FeesGet(data).subscribe(res=>{
      this.dataArr=res;
      
    })
  
  }}  
      