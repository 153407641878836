<div class="container-fluid">

    <img src="assets/banners/exam.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Training Modes  </h1>

   

  

 
	  <div class="container">
	   
	  <hr/>
	   <div class="col-md-12 side-bg pad">
	   <h4 style="text-align:center; font-weight:bold;"><span style="background:#ff5100; color:#fff; padding:4px 10px; border-radius:5px;">Regular Mode</span></h4>
	   <p>Students who would like to attend classes in regular mode and attend practical classes can join the vocational programmes in regular mode. The classes will be conducted systematically by expert faculties who have several years of experience. 75% attendance is required to attend exams. For regular mode students need to visit our affiliated vocational training centre only.</p>
	   <h5 style="text-align:left; color:#fff; text-decoration:underline;">Eligibility : </h5>
	   <p>Students who have passed X Standard or XII Standard as may be required will be strictly followed for Regular admissions. Students who have not passed X Standard or XII Standard as the condition may be will not be considered for admission to regular vocational programmes.</p>
	   <h5 style="text-align:left; color:#fff; text-decoration:underline;">Examination Procedure : </h5>
	   <p>The Examination will be conducted systematically in written format or may be online and invigilators will inspect the Examination hall. Practical Examination will be conducted as per Institution norms. Students who do not appear for all examinations will be marked absent. Results will be announced in within 30-45 days from the date of completion of examination Certificates will be awarded based on whether the student has passed the exam or not. Regular Certificate will be awarded for the students who pass the exams in regular mode.</p>
	   </div>
	   <hr/>
	   <div class="col-md-12 side-bg pad">
	   <h4 style="text-align:center; font-weight:bold;"><span style="background:#ff5100; color:#fff; padding:4px 10px; border-radius:5px;">Distance / Online Mode</span></h4>
	   <p>Distance/Online Training is a part of KIT-VIT and is committed to provide quality vocational education through Distance Mode/Online Mode.<br/>
Understanding the characteristics of successful distance learners is necessary to help identify strategies that are highly effective for a successful distance learning experience. It will be important to determine if learning occurs differently via distance. It is necessary to identify processes teachers use to customize the learning experience and assess “innovative” instructional processes to identify which best help students learn. It’s important to diagnose problems that learners encounter in terms of time and place, along with how the required use of technology affects their motivation. It is also important to determine student expectations for credit courses and why they do not take advantage of distance learning opportunities. It is more important to determine expectations for professional improvement than it is to determine expectations for personal enrichment. It will be important to determine participation and completion rates and assess outcomes of vocational education through Distance Mode.<br/><br/>
Directorate of Distance Learning will take necessary steps to evolve strategies to promote vocational education through Distance Mode and take steps to make it a life-long process of learning system.</p>
<h5 style="text-align:left; color:#fff; text-decoration:underline;">Examination Procedure : </h5>
<p>All the Vocational Education courses are One Year Certificate Courses. Exams will be conducted in non-semester mode in the prescribed format. The Examination will be conducted in the written/Online format and once the student passes the exam the certificate will be issued mentioning that the student has passed through Distance Mode.</p>
</div>
<hr/>
<div class="col-md-12 side-bg pad">
 <h4 style="text-align:center; font-weight:bold;"><span style="background:#ff5100; color:#fff; padding:4px 10px; border-radius:5px;">IITP Mode</span></h4>
 <p><strong>IITP</strong> stands for <strong>Industry Internship Training Program.</strong> All the course which are  running by KIT-VIT students have to following this mode for getting better opportunities in different-2 industries and Sectors. Any Students have a great opportunity for going as a 6-Month Industrial Training for their joined courses in both mode regular and distance online mode. This Internship may be Paid or unpaid for students. All the process will be properly explained before going to internship for a specific period.  An <strong>internship</strong> is a period of Work Experience offered by an organization or Companies for a limited period of time.  They are typically undertaken by students and graduates looking to gain relevant skills and experience in a particular field. Employers benefit from these placements because they often recruit employees from their best interns, who have known capabilities, thus saving time and money in the long run. Internships are usually arranged by third-party organizations which recruit interns on behalf of industry groups. Rules vary from country to country about when internships should be regarded as employees. The system can be open to exploitation by unscrupulous employers.<br/><br/>
<strong>KIT-VIT-Gives you all the opportunities related to internships training programs for enhancing your skills in a particular industry or Sectors. Just join the course in any mode –Regular or Distance/Online- and fulfill your dreams.</strong></p>
</div>
<hr/>
<div class="col-md-12 side-bg pad">
<h4 style="text-align:center; font-weight:bold;"><span style="background:#ff5100; color:#fff; padding:4px 10px; border-radius:5px;">WITP Mode</span></h4>
<p> Work Integrated Training Programme is where students learn the skill by engaging in on-the –job training at real shop floor of the industry/Company. For effective delivery of this model, we will leveraging on the expertise of a partner that will provide classroom-based training and will be known as VTPs (Vocational Training Partners). All the VTPs will enroll the students for Internships but before sending as an Internship Trainee the VTPs will provide classroom theoretical training for students.<br/><br/> Where students will get their industry training/Job training –these will called KIT-VIT/NGP Educare Industry Skill Partner. All Industry/Sector Skill Partner Will responsible for providing the live or actually training to the students or working trainee/staff etc.</p>
<h5 style="text-align:left;color:#fff; text-decoration:underline;">Aims of KIT-VIT WITP Mode : </h5>
<ul class="list">
 <li>The aim of our WITP is to provide the trainee the opportunity to enhance their skills and gain workplace-based industry experience as a part of their courses.  For the trainee, the experience is valuable as training is built around the context of work, enabling them to learn by working and observing.</li>

<li>This model also allows for design of the programme with an ‘Earn while you Learn ‘feature. This feature enables the trainee to earn a stipend during on-the-job duration of the course of training to support their simultaneous vocational Training.</li>
<li>KIT-VIT through its VTPs, will provide the requisite structured classroom sessions while inducting them into the work settings. The WITP programme would further enable students to perform better in the highly technically advanced world by providing them with a platform to learn the duties and responsibilities of various employees while they are student-trainees at the facility.</li>
<li>KIT-VIT gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.</li>
</ul>
<p><strong>Therefore, join hands with us as a Student /Trainees, VTPs and Industry Skill Partner for inclusive growth of all.</strong></p>
</div>
	  </div>


