
<h1> Law Courses </h1>
<b>Career in Law Sector : </b> 

देश-दुनिया में अब फौजदारी और दीवानी मामलों के साथ ही साइबर क्राइम्स से जुड़े मामले भी लगातार बढ़ रहे हैं. इसी तरह, टैक्स और जीएसटी को लेकर भी साधारण लोगों को लीगल एक्सपर्ट्स की सेवाओं की जरूरत अक्सर पड़ती ही रहती है. बेशक, हमारे देश सहित पूरी दुनिया में लॉ की विभिन्न फ़ील्ड्स में जॉब या प्राइवेट प्रैक्टिस की संभावनाएं लगातार बढ़ती ही जा रही हैं. ऐसे में, अगर आप भी भारत के ऐसे ही यंगस्टर्स में से एक हैं जो लॉ की किसी फील्ड में अपना करियर शुरू करना चाहते हैं तो इस आर्टिकल को जरुर बड़े ध्यान से पढ़ें क्योंकि यहां हम आपके लिए लॉ की विभिन्न फ़ील्ड्स में करियर शुरू करने के लिए समुचित जानकारी पेश कर रहे हैं.

लॉ: आपके लिए बेहतरीन करियर ऑप्शन

लॉ एक ऐसा प्रोफेशन है जिसके लिए धैर्य और लॉजिकल स्किल्स आवश्यक होते हैं. लॉ में, सफलता चंद दिनों में ही नहीं मिल जाती है क्योंकि इसके लिए काम के प्रति पूरी लगन और एक सफल लॉयर बनने के लिए प्रतिबद्धता बहुत आवश्यक हैं. अगर कोई व्यक्ति अपने करियर की शुरुआत में किसी सीनियर लॉयर/ काउंसेल के अधीन ट्रेनिंग प्राप्त करे तो उसके लिए अपना लक्ष्य प्राप्त करना काफी आसान हो जाता है. फ़्लूएंट कम्युनिकेशन स्किल्स और सीमित समय के भीतर संबद्ध केस को सही तरीके से एनालाइज और समझने/ समझाने की काबिलियत लॉयर्स के लिए जरुरी शर्तें हैं. इसलिए, लॉ को एक करियर ऑप्शन के तौर पर चुनने से पहले हरेक व्यक्ति को इन प्वाइंट्स पर अच्छी तरह विचार कर लेना चाहिए.


<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT & NGP Educare Gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> Therefore, Join Your Hands With Us As a Student /Trainees, VTPs and Industry Skill Partner For inclusive growth of All.


<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->
<h1 class="headi1">Courses <span>& Eligibility</span></h1>
<!--Degree Courses START HERE-->

<h3 style="text-align:center"> Degree Courses</h3>

<div class="table-responsive table-bordered  table-hover  ">
  <table class="table-striped">
    <tbody>
      <tr>
        <th>Sr. No.</th>
        <th>Course Name</th>
        <th>Duration</th>
        <th>Eligibility</th>
        <th>Education Mode </th>
        <th scope="col">Action</th>
      </tr>
      <tr>
        <td>1.</td>
        <td>Bachelor of Law </td>
        <td>3-Year</td>
        <td>Any Graduate</td>
        <td>Regular only </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>
      <tr>
        <td>2.</td>
        <td>Master of Law </td>
        <td>2-Year</td>
        <td>LLB</td>
        <td>Regular/Distance </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>
     

  </tbody>
  </table>
</div>


<!--DEGREE Courses END HERE-->





