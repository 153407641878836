import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccordionComponent } from 'ngx-bootstrap/accordion';
import { AgricultureCoursesComponent } from './agriculture-courses/agriculture-courses.component';
import { ArtsCoursesComponent } from './arts-courses/arts-courses.component';
import { AyurvedaCoursesComponent } from './ayurveda-courses/ayurveda-courses.component';
import { BeautyCoursesComponent } from './beauty-courses/beauty-courses.component';
import { DistanceUniversityComponent } from './distance-university/distance-university.component';
import { ETuitionComponent } from './e-tuition/e-tuition.component';
import { EduSliderComponent } from './edu-slider/edu-slider.component';
import { EducationComponent } from './education/education.component';
import { EngineeringCoursesComponent } from './engineering-courses/engineering-courses.component';
import { FashionCoursesComponent } from './fashion-courses/fashion-courses.component';
import { FinanceCoursesComponent } from './finance-courses/finance-courses.component';
import { HotelCoursesComponent } from './hotel-courses/hotel-courses.component';
import { ITCoursesComponent } from './it-courses/it-courses.component';
import { KeyEduComponent } from './key-edu/key-edu.component';
import { LawCoursesComponent } from './law-courses/law-courses.component';
import { MediaCoursesComponent } from './media-courses/media-courses.component';
import { NursingCoursesComponent } from './nursing-courses/nursing-courses.component';
import { OpenSchoolComponent } from './open-school/open-school.component';
import { ParamedicalCoursesComponent } from './paramedical-courses/paramedical-courses.component';
import { RegularUniversityComponent } from './regular-university/regular-university.component';
import { RetialCoursesComponent } from './retial-courses/retial-courses.component';
import { SafetyCoursesComponent } from './safety-courses/safety-courses.component';
import { SkillBasedComponent } from './skill-based/skill-based.component';
import { TeacherCoursesComponent } from './teacher-courses/teacher-courses.component';
import { TuitionDetailsComponent } from './tuition-details/tuition-details.component';

const routes: Routes = [
  {path : '', redirectTo: '/keyworldeducation',pathMatch: 'full'},

  
  {path: 'eduslider', component: EduSliderComponent},
  {path: 'accordion', component: AccordionComponent},
  
  {path: '', component: EducationComponent, children:[

  {path: 'keyworldeducation', component: KeyEduComponent},
  {path: 'e-tuition', component : ETuitionComponent},
  {path: 'tuition-details', component: TuitionDetailsComponent},
  
  {path: 'open-school', component: OpenSchoolComponent},
  
  {path:'skill-based', component:SkillBasedComponent},
  {path:'finance-courses',component:FinanceCoursesComponent},
  {path: 'hotel-courses',component :HotelCoursesComponent},
  {path: 'computer-courses',component : ITCoursesComponent},
  {path: 'beauty-courses', component : BeautyCoursesComponent},
  {path: 'safety-courses', component : SafetyCoursesComponent},
  {path: 'teacher-courses', component : TeacherCoursesComponent},
  {path: 'retail-courses', component : RetialCoursesComponent},
  {path: 'fashion-courses',component : FashionCoursesComponent},
  {path: 'engineering-courses', component: EngineeringCoursesComponent },
  {path: 'agriculture-courses', component: AgricultureCoursesComponent},
  {path: 'arts-courses', component : ArtsCoursesComponent},
  {path: 'media-courses',component : MediaCoursesComponent},
  {path: 'paramedical-courses',component : ParamedicalCoursesComponent},
  {path: 'ayurveda-courses', component : AyurvedaCoursesComponent},
  {path: 'nursing-courses', component : NursingCoursesComponent},
  {path: 'law-courses', component : LawCoursesComponent},
  {path: 'regular-university', component: RegularUniversityComponent},
  {path: 'distance-university', component:DistanceUniversityComponent },
  




  

  ]}
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EEducationRoutingModule { }
