import { Component, OnInit } from '@angular/core';
import {CarouselConfig} from 'ngx-bootstrap/carousel';

@Component({
  selector: 'app-smartpage',
  templateUrl: './smartpage.component.html',
  styleUrls: ['./smartpage.component.css'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 5000, noPause: true, showIndicators: true,
      pauseOnFocus:false,  slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: true,
      autoplaySpeed: 1500,
      infinite: true,  } }
  ]
})
export class SmartpageComponent implements OnInit {

  itemsPerSlide = 3;
  singleSlideOffset = true;
  noWrap = false;
  showIndicator=false;
 
  slides = [
    {image: 'assets/smartcard/advt/1.jpg'},
    {image: 'assets/smartcard/advt/2.jpg'},
    {image: 'assets/smartcard/advt/3.jpg'},
    {image: 'assets/smartcard/advt/4.jpg'},
    {image: 'assets/smartcard/advt/5.jpg'},
    


    
  ];
 
  onSlideRangeChange(indexes: number[]): void {
   
  }


  constructor() { }

  ngOnInit(): void {
  }

}

