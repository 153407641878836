import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { data, post } from 'jquery';
import { getLocaleDateFormat } from '@angular/common';
@Injectable({
  providedIn:'root'
})

export class MyserviceService {
  getusername() {
    throw new Error('Method not implemented.');
  }
  

  
  baseUri:string = 'http://127.0.0.1:8080/public/schooladmin/api/';
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  
 
 constructor(private _http: HttpClient) { }
 
   /*/ For Locahost server testing function start here  
getData()
{
  return this._http.get('http://127.0.0.1:8080/public/schooladmin/api/')
}


  // for contactus form  query sending function  

  submitData (data:any){
    return this._http.post('http://127.0.0.1:8080/public/schooladmin/api/testing',data);
  }

 // for contactus form  query sending function end here 

// for contactus form  query sending function  

imageGallery (data:any){
  return this._http.get('http://127.0.0.1:8080/public/schooladmin/api/gallery',data);
}

// For News & Events  function   start
newsGet (data:any){
  return this._http.get('http://127.0.0.1:8080/public/schooladmin/api/news',data);
}

// For Fees Structure  function   start
FeesGet (data:any){
  return this._http.get('http://127.0.0.1:8080/public/schooladmin/api/fees',data);
}



//For Showing Laravel Data into Angular Download menu 
getDownload(data:any)
{
  return this._http.get('http://127.0.0.1:8080/public/schooladmin/api/legal',data);
}

//For Showing Laravel Data into Angular Download menu 

DownloadFile(data:any)
{
  return this._http.get('http://127.0.0.1:8080/public/schooladmin/api/legal',data);
}


*/


 /* For Live Server connectivity detailed functions start here  */ 

 
getData()
{
  return this._http.get('https://kaveripublicschool.in/schooladmin/api/')
}


  // for contactus form  query sending function  

  submitData (data:any){
    return this._http.post('https://kaveripublicschool.in/schooladmin/api/testing',data);
  }

 // for contactus form  query sending function end here 

// for contactus form  query sending function  

imageGallery (data:any){
  return this._http.get('https://kaveripublicschool.in/schooladmin/api/gallery',data);
}

// For News & Events  function   start
newsGet (data:any){
  return this._http.get('https://kaveripublicschool.in/schooladmin/api/news',data);
}

// For Fees Structure  function   start
FeesGet (data:any){
  return this._http.get('https://kaveripublicschool.in/schooladmin/api/fees',data);
}



//For Showing Laravel Data into Angular Download menu 
getDownload(data:any)
{
  return this._http.get('https://kaveripublicschool.in/schooladmin/api/legal',data);
}

//For Showing Laravel Data into Angular Download menu 

DownloadFile(data:any)
{
  return this._http.get('https://kaveripublicschool.in/schooladmin/api/legal',data);
}






 /* for contactus form  query sending function  */

 eduData (data:any){
  return this._http.post('https://keyworldbiz.com/superadmin/api/eduquery',data);
 }
}



// for contactus form  query sending function end here 

/*/ for contactus form  query sending function  

branchData (data:any){
  return this._http.post('https://keyworldbiz.com/superadmin/api/branchquery',data);

}
// for contactus form  query sending function end here 

//For Showing Laravel Data into Angular Download menu 

getDownload(data:any)
{
  return this._http.get('http://127.0.0.1:8080/public/superadmin/api/download',data);
}

//For Showing Laravel Data into Angular Download menu 

DownloadFile(data:any)
{
  return this._http.get('http://127.0.0.1:8080/public/superadmin/api/download/',data);
}


}

}
*/
