import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-keyworld-smartcard',
  templateUrl: './keyworld-smartcard.component.html',
  styleUrls: ['./keyworld-smartcard.component.css']
})
export class KeyworldSmartcardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
