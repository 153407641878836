<div class="container-fluid">

    <img src="assets/banners/sec.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Secretary-Message    </h1>
   <br> <br> <br>
   <div class="container">
   
  

    <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <img class="img2" src="assets/banners/sec.jpg" style="width:100%;height:auto;"  />
            <h2> Admin-cum-Secretary  </h2>
        </div>
          <div class="col-sm-8">
            <p> “It’s my pleasure to becoming the part of Kewal Institue of Technical & Vocational Industrial Training. KIT-VIT has been established to conduct Vocational Industrial Training Programmes for enhancing the skills of youths in rural and urban areas of India. KIT-VIT is a Centre for Excellence for conducting Vocational Training Programmes.
              <br><br>
              Vocational training is broadly defined as any type of job-related learning that raises an individual’s productivity, and includes learning in formal/Informal vocational and technical school programmes in training centers or institutes, and in the workplace, both on and off the job. India needs more number of skilled people to work in the industries, but the number of people actually trained with the skill sets is very less. This has created a huge vacuum in the Industrial Sector. This vacuum has dampened the progress of industries. When the skilled labour increases, it automatically increases the output and also the quality of output becomes better. So we are into the process of decreasing the unskilled operations and increasing skilled labour.
              <br><br>
              We would like to play a major role in the development of our nation through the promotion of Vocational Industrial Training Courses.
              <br><br>
              The Secretary
              (Kewal Institute of Technical & Vocational Industrial Training.)"
 
            
            </p>
            <h2> Secretary : KIT-VIT & NGP Educare  </h2>
          </div>
</div>

<br> <br> <br>
  
  
  
  