
<h1> Agriculture Courses </h1>
<b>Career in Agriculture Sector : </b> 

एग्रीकल्चर कैरियर के लिए काफी हॉट कैरियर माना जाता है क्योकि भारत मे कृषि प्रमुख व्यवसाय में से एक है। आधिकांश ग्रामीण लोग कृषि पर ही निर्भर है। इसलिए इस सेक्टर में कैरियर को लेकर को असमंजस की स्थिति नही है।
<br>
प्राइवेट और गवर्नमेंट दोनो क्षेत्रों में जॉब के अवसर मिलते हैं। जिस प्रकार हमारे देश मे जनसंख्या बढ़ रही है, ऐसे में ऐसी कृषि तकनीक और गुणवत्ता युक्त बीज और खाद की जरूरत होगी। जिससे की ज्यादा से ज्यादा आनाज का उत्पादन हो और बढ़ती जनसंख्या को आहार की पूर्ति की जा सके। इसलिए इस सेक्टर में कृषि विशेषज्ञों के लिए काफी अच्छी संभावनाएं है।
<br>
एग्रीकल्चर के सेक्टर में आप बागबानी, डेयरी, पोल्ट्री फार्मिंग, खाद्य विज्ञान, पैदा विज्ञान, मृदा विज्ञान, एग्रीकल्चर इंजीनियरिंग, स्टेट एंड टी गार्डन्स, कॉरपोरेट, एग्रीकल्चर मैनेजमेंट आदि क्षेत्रों कैरियर बनाया जा सकता है। कोर्स पूरा करने के बाद आप कृषि उद्योग, कृषि व्यवसाय जैसे स्वरोजगार भी कर सकते हैं। Bsc Agriculture के बाद एमएससी एग्रीकल्चर या किसी भी कृषि के सेक्टर में विशेषज्ञता भी हासिल कर सकते हैं। इसके बाद आप टीचिंग और रिसर्च जैसे क्षेत्रों में भी कैरियर बना सकते हैं। इसमे आप निम्न क्षेत्रों में जॉब कर सकते हैं।
<br>
इस सेक्टर में 10 से 15 हजार रुपये एंट्री लेवल पर आसानी से मिल जाते हैं। कुछ साल का अनुभव होने पर 30 से 50 हजार की सैलरी भी आप सकते हैं। इसमे टीचिंग और रिसर्च तथा एग्रीकल्चर इंजीनियर को काफी आकर्षक सैलरी मिलती है। अगर आप एग्रीकल्चर के अंतर्गत किसी भी फील्ड में स्पेशलाइजेशन करते हैं, तो आपकी सैलरी में और ज्यादा इज़ाफ़ा हो जाता है।
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> KIT-VIT & NGP Educare Gives opportunities even for those who is working in industries already they have skills but not certification for their promotions can also join our WITP modes.     
<br>
<i class="fa fa-check fa-2x" aria-hidden="true"></i> Therefore, Join Your Hands With Us As a Student /Trainees, VTPs and Industry Skill Partner For inclusive growth of All.



<!--MODAL OF Courses APPLY FORM  START HERE-->

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">Apply Now Today </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="formbody">
          <form style="width:100%;" [formGroup]="myForm"(ngSubmit)="submit()">
          
          <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b> Name : </b> </mat-label> 
          <input type="text" formControlName="name" matInput placeholder="Enter Your Name: ">
                    
          </mat-form-field>
                   
          </div>   
        
         <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label> <b>  Email : </b> </mat-label> 
          <input type="email" formControlName="email" matInput placeholder="Enter Your Email:">
                    
          </mat-form-field>
                   
          </div>         
        
          <div>
          <mat-form-field appearance= "fill" style="width:100%;">
          
          <mat-label> <b> Mob No :</b> </mat-label> 
          <input type  = "text" formControlName ="mobile" matInput placeholder=" Enter Your Mob No :">
                    
          </mat-form-field>
                   
          </div>    
        
        <div>
          <mat-form-field appearance="fill"style="width:100%;">
          
          <mat-label>  <b> Message or Any query: </b> </mat-label> 
          <textarea placeholder= "text" formControlName="message" rows="2" matInput > </textarea>
        
                    
          </mat-form-field>
                   
          </div>         
        
        <input type="submit"  value="submit"  class="btn btn-success btn-lg" button style="margin:10px;"/> 
            <input type="submit" value="Cancel" class="btn btn-danger btn-lg" /> 
        
        
        
        </form>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-dismiss="modal">Close Now</button>
      
      </div>
    </div>
  </div>
</div>

<!--MODAL OF Courses APPLY FORM  END HERE-->


<!--TABLE OF Courses START HERE-->

<div class="row div-pad">
  <div class="container">
 <h1 class="headi1">Courses <span>& Eligibility</span></h1>

				

<!--DIPLOMA Courses START HERE-->

      <h3 style="text-align:center"> Diploma Courses</h3>

      <div class="table-responsive table-bordered  table-hover  ">
				<table class="table-striped">
					<tbody>
						<tr>
							<th>Sr. No.</th>
							<th>Course Name</th>
							<th>Duration</th>
							<th>Eligibility</th>
              <th>Education Mode </th>
              <th scope="col">Action</th>
						</tr>
						<tr>
							<td>1.</td>
							<td>Diploma in Agriculture Science</td>
							<td>1-Year</td>
							<td>12th Pass</td>
              <td>Regular Only </td>
              <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
              </td>
						</tr>
					
           




        					</tbody>
				</table>
			</div>


<!--DIPLOMA Courses END HERE-->


<!--Degree Courses START HERE-->

<h3 style="text-align:center"> Degree Courses</h3>

<div class="table-responsive table-bordered  table-hover  ">
  <table class="table-striped">
    <tbody>
      <tr>
        <th>Sr. No.</th>
        <th>Course Name</th>
        <th>Duration</th>
        <th>Eligibility</th>
        <th>Education Mode </th>
        <th scope="col">Action</th>
      </tr>
      <tr>
        <td>1.</td>
        <td> Bachelor of Science(Agriculture) </td>
        <td>4-Year</td>
        <td>12-PCB/PCM</td>
        <td>Regular Only </td>
        <td > <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo">Apply Now</button>
        </td>
      </tr>
      <tr>
        <td>2.</td>
        <td>Master of Science (Agriculture)</td>
        <td>2-Year</td>
        <td>B.Sc. in AG/HO</td>
        <td>Regular only </td>
        <td > <button type="button" class="btn btn-success btn-sm"data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo"> Apply Now </button></td>
      </tr>
      



  </tbody>
  </table>
</div>


<!--DEGREE Courses END HERE-->




