import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tuition-details',
  templateUrl: './tuition-details.component.html',
  styleUrls: ['./tuition-details.component.css']
})
export class TuitionDetailsComponent implements OnInit {
  myForm: any;

  constructor() { }

  ngOnInit(): void {
  }
  
  submit(){
    console.log(this.myForm.value);
  }
}
