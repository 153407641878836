import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';


import { data, event } from 'jquery';
import { MyserviceService } from 'src/app/Login Services';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  image:any;
  dataArr:any;
  data: any;
  // Gallery Images path for localhost
  //imageDirectoryPath:any ='http://127.0.0.1:8080/public/schooladmin/public/images/';

  // Gallery Images path for live server
  imageDirectoryPath:any ='https://kaveripublicschool.in/schooladmin/public/images/';

  constructor(
      private myservice:MyserviceService
  ) { }

  ngOnInit() {

    this.imageGallery();
    this.DownloadFile();
  }
  DownloadFile() {
    throw new Error('Method not implemented.');
  }
  imageGallery()
  {
    this.myservice.imageGallery(data).subscribe(res=>{
      this.image=res;
      
    })
  
    // jqery function

    $(document).ready(function(){

      $('.buttons').click(function(){
  
          $(this).addClass('active').siblings().removeClass('active');
  
          var filter = $(this).attr('data-filter')
  
          if(filter == 'all'){
              $('.image').show(400);
          }else{
              $('.image').not('.'+filter).hide(200);
              $('.image').filter('.'+filter).show(400);
          }
  
      });
  
     
      

  
  });
  
  }}
      
   