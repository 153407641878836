import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AboutusComponent } from './aboutus/aboutus.component';

import { ContactusComponent } from './contactus/contactus.component';
import { CurriculumComponent } from './curriculum/curriculum.component';
import { DownloadComponent } from './download/download.component';

import { ExaminationComponent } from './examination/examination.component';
import { FessComponent } from './fess/fess.component';
import { FooterComponent } from './footer/footer.component';
import { FrontOfficeComponent } from './front-office/front-office.component';
import { GalleryComponent } from './gallery/gallery.component';
import {HeaderComponent} from './header/header.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { HomeComponent } from './home/home.component';
import { LabsComponent } from './labs/labs.component';
import { LegalComponent } from './legal/legal.component';

import { MissionComponent } from './mission/mission.component';
import { MusicComponent } from './music/music.component';
import { NewsComponent } from './news/news.component';
import { OtherStaffComponent } from './other-staff/other-staff.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PresidentMessageComponent } from './president-message/president-message.component';
import { PrincipalMessageComponent } from './principal-message/principal-message.component';
import { SportsWinnersComponent } from './sports-winners/sports-winners.component';

import { TeachingStaffComponent } from './teaching-staff/teaching-staff.component';
import { TheoryRoomComponent } from './theory-room/theory-room.component';

import { ToppersComponent } from './toppers/toppers.component';

const routes: Routes = [
  {path:'header',component:HeaderComponent},
  {path:'footer',component:FooterComponent},
  {path:'', component:HomeComponent},
  {path:'home',component : HomeComponent},
  {path:'page-not-found',component:PageNotFoundComponent},
  {path:'home-slider',component:HomeSliderComponent},
  
  
  {path: 'aboutus',component:AboutusComponent},
  {path:"mission", component:MissionComponent},
  {path:"curriculum",component:CurriculumComponent },
  {path:"examination", component:ExaminationComponent},
  {path:"teaching",component:TeachingStaffComponent},
  {path:"nonteaching", component:OtherStaffComponent},
  {path:"legal",component:LegalComponent},

  {path:"fees", component:FessComponent},
 

  {path:"labs", component:LabsComponent},
  {path:"toppers", component:ToppersComponent},
  {path:"music", component:MusicComponent},
  {path:"sportswin",component:SportsWinnersComponent},
  {path:"news", component:NewsComponent},
  {path:"owner-message", component:PrincipalMessageComponent},
  {path:"secretary",component:PresidentMessageComponent},
  {path: "frontoffice", component:FrontOfficeComponent},
  {path: "theoryroom", component:TheoryRoomComponent},
  {path: "download",component:DownloadComponent},
  {path: 'gallery', component:GalleryComponent},
  {path:'contactus', component:ContactusComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
