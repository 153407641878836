
<!-- <title>Responsive Navbar</title> -->

<!-- header designs -->

<div class="header">
  <!-- Top bar Start -->
          <div class="top-bar">
              <div class="container-fluid">
                  <div class="row">
                      <div class="col-sm-2">
                        <div  class="logo1"><a href="#"><img src="assets/logo.jpg"></a></div> </div>
                        
                        <div class="col-sm-6">
                          <h1 class="name"> NGP(Next-Gen.Path) Educare <br>
                          <small> केवल तकनीकी एवं औद्योगिक व्यवसायिक प्रशिक्षण संस्थान <br>  </small></h1>
                         <h5>An Autonomous Institute Register Under The Lagislation of Govt. of India For Voctional Training </h5> 
                      </div>
                      
                      <div class="col-sm-4">
                        <button type="button" class="btn btn-danger btn-lg  mr-4" (click)="openModal(template)"> <b>Login</b> </button>   
                          <button type="button" class="btn btn-success btn-lg mr-4" > <b>Pay Fees</b> </button> 
                          <button type="button" class="btn btn-dark btn-lg mr-4" > <b>Verify</b> </button> <br><br>
                          <i class="fa fa-phone"></i>
                          For Call & Whatsapp : +91-80911-80311,<br> +91-80912-80311,+91-80912-80312.
                         
                          
                      </div>
                  </div>
              </div>
          </div>
          <!-- Top bar End -->
  
           <!-- Nav Bar Start -->
          <div class="nav">
            <div class="container-fluid" id ="nav-container">
                  <nav class="navbar navbar-expand-md">
                      <a href="#" class="navbar-brand"></a>
                      <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                          <span class="navbar-toggler-icon"></span> MENU
                      </button>
  
                      <div class="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                          <div class="navbar-nav mr-auto">
                       
  
   <div class="row top-div navbar" data-spy="affix" data-offset-top="197">
		
				<div id='cssmenu'>
					<ul>
						<li class="active"><a routerLink="home" routerLinkActivate = "Activate">Home</a></li>
						<li class="has-sub"><a routerLink="aboutus" routerLinkActivate = "Activate"> About us</a>
							<ul>
								<li><a routerLink="aboutus" routerLinkActivate = "Activate"> About Institute</a></li>
								<li><a routerLink="owner-message" routerLinkActivate = "Activate"> CMD Message</a></li>
								<li><a routerLink="secretary" routerLinkActivate = "Activate"> Secretary Message</a></li>
								<li> <a routerLink="mission" routerLinkActivate = "Activate">Mission & Vision</a></li>
                <li><a routerLink="teaching" routerLinkActivate = "Activate"> Teaching Staff</a></li>
								<li><a routerLink="nonteaching" routerLinkActivate = "Activate"> Non-Teaching Staff</a></li>
							</ul>
						</li>
						<li class="has-sub"><a routerLink="curriculum" routerLinkActivate = "Activate">Courses</a>
							<ul>
                <li><a routerLink="keyworldeducation" routerLinkActivate="active"> All Courses</a></li>
								<li><a routerLink="skill-based" routerLinkActivate = "Activate"> Skill-Based Courses</a></li>
                <li><a routerLink="keyworldeducation" routerLinkActivate = "Activate"> Academy Courses</a></li>
								<li><a routerLink="regular-university" routerLinkActivate = "Activate"> University Courses</a></li>
								
								
								<li><a routerLink="curriculum" routerLinkActivate = "Activate"> Training Modes</a></li>
								<li><a routerLink="examination" routerLinkActivate = "Activate"> Examinations</a></li>
                <li><a routerLink="fees" routerLinkActivate = "Activate"> Fees Structures</a></li>
								
							</ul>
						</li>

						<li class="has-sub"><a routerLink="teaching" routerLinkActivate = "Activate"> Branches</a>
							<ul>
                <li><a routerLink="teaching" routerLinkActivate = "Activate"> All Branches </a></li>
                <li><a routerLink="teaching" routerLinkActivate = "Activate"> Become Branch </a></li>
								
								
							</ul>
						</li>

            <li class="has-sub"><a href='#'>Legal</a>
							<ul>
								<li><a routerLink="legal" routerLinkActivate = "Activate"> Accreditations</a></li>
							
								
							</ul>
						</li>

            <li class="has-sub"><a routerLink="admission" routerLinkActivate = "Activate">Download</a>
							<ul>
								<li><a routerLink="download" routerLinkActivate = "Activate"> Download Files </a></li>
              
							
								
							</ul>
						</li>

            <li class="has-sub"><a routerLink='frontoffice' routerLinkActivate="Active">Facilities</a>
							<ul>
                <li><a routerLink="frontoffice" routerLinkActivate = "Activate"> Front Office</a></li>
								<li><a routerLink="labs" routerLinkActivate = "Activate"> Computer Lab </a></li>
                <li><a routerLink="theoryroom" routerLinkActivate = "Activate">Theory Rooms </a></li>
   
							
							
								
							</ul>
						</li>

						<li class="has-sub"><a href="#">Gallery</a>
							<ul>
								<li><a routerLink="gallery" routerLinkActivate = "Activate">Photo Gallery</a></li>
							
							</ul>
						</li>

            <li class="has-sub"><a routerLink="toppers" routerLinkActivate = "Activate"> Achievements</a>
							<ul>
								<li><a routerLink="toppers" routerLinkActivate = "Activate"> Govt.Projects </a></li>
								<li><a routerLink="sportswin" routerLinkActivate = "Activate"> Job-Placement</a></li>
                <li><a routerLink="music" routerLinkActivate = "Activate"> Selection in Govt.</a></li>

							</ul>
						</li>

            <li class="has-sub"><a href="#">News</a>
							<ul>
								<li><a routerLink="news" routerLinkActivate = "Activate"> News & Events </a></li>
								

							</ul>
						</li>
           	<li><a routerLink="contactus" routerLinkActivate = "Activate"> Contact Us</a></li>

					</ul>
				</div>
			</div>
		</div>
		<div>              
                                 
  </div>
   
  
                        
      
                       
  
                             
              
                          </div>
       <!-- LOGIN Modal  Designs -->
  
   
  <ng-template #template>
  
    <div class="modal-header">
      <h4 class="modal-title pull-center">LOGIN</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
   <div class="container" id ="modal-container">
    
  <div class="col-md-6 col-sm-6 col-xs-6 pad1">
           <div class="col-md-12 col-sm-10 log">
            <a href="https://kaveripublicschool.in/schooladmin/login" target="_blank"> <i class="fa fa-sign-in fa-5x" aria-hidden="true"></i> <br/>
              <h4>Admin Login</h4>
             </a>
            </div>
           </div>
  
  <div class="col-md-6 col-sm-6 col-xs-6 pad1">
            <div class="col-md-12 col-sm-12 log">
              <a href="https://kaveripublicschool.in/" target="_blank"> <i class="fa fa-sign-in fa-5x" aria-hidden="true"></i> <br/>
              <h4>ERP Login</h4>
             </a>
            </div>
           </div>
    </div>      
  <div class="modal-footer">
         <h3> Welcome To School Management Panel </h3>
        
      </div>
  
    </div>
  </ng-template>
  
                                  
                           
                  </nav>
              </div>
          </div>
  
          
     