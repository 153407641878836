<div class="container-fluid">

    <img src="assets/banners/staff.jpg" alt="first slide" style="display: block; width: 100%;">
    
  
  </div>
   <h1> Our Teaching Staff    </h1>
  
   <h2>List of Teachers</h2>
   <br>

   <div class="container">
    <div class="row">

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="our-team">
          <div class="picture">
            <img class="img1" src="assets/icons/2.png" style="width:100%;height:100%;"  /><br>
          </div>
          <div class="team-content">
            <h3 class="name">STAFF NAME </h3>
            <h4 class="title">Qualification:........</h4>
            <h4 class="title">Experience:...........</h4>
          </div>
          <ul class="social">
            <li><a href="#"> NGP Educare Pvt.Ltd.</a></li>
            
          </ul>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="our-team">
          <div class="picture">
            <img class="img1" src="assets/icons/2.png" style="width:100%;height:100%;"  /><br>
          </div>
          <div class="team-content">
            <h3 class="name">STAFF NAME </h3>
            <h4 class="title">Qualification:........</h4>
            <h4 class="title">Experience:...........</h4>
          </div>
          <ul class="social">
            <li><a href="#"> NGP Educare Pvt.Ltd.</a></li>
            
          </ul>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="our-team">
          <div class="picture">
            <img class="img1" src="assets/icons/2.png" style="width:100%;height:100%;"  /><br>
          </div>
          <div class="team-content">
            <h3 class="name">STAFF NAME </h3>
            <h4 class="title">Qualification:........</h4>
            <h4 class="title">Experience:...........</h4>
          </div>
          <ul class="social">
            <li><a href="#"> NGP Educare Pvt.Ltd.</a></li>
            
          </ul>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="our-team">
          <div class="picture">
            <img class="img1" src="assets/icons/2.png" style="width:100%;height:100%;"  /><br>
          </div>
          <div class="team-content">
            <h3 class="name">STAFF NAME </h3>
            <h4 class="title">Qualification:........</h4>
            <h4 class="title">Experience:...........</h4>
          </div>
          <ul class="social">
            <li><a href="#"> NGP Educare Pvt.Ltd.</a></li>
            
          </ul>
        </div>
      </div>


    </div>
  </div>