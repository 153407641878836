import { Component, OnInit } from '@angular/core';


import * as $ from 'jquery';


import { data, event } from 'jquery';
import { MyserviceService } from 'src/app/Login Services';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  image:any;
  dataArr:any;
  data: any;
  //imageDirectoryPath:any ='http://127.0.0.1:8080/public/schooladmin/public/images/';
  constructor(
      private myservice:MyserviceService
  ) { }

  ngOnInit() {
    this.newsGet();

  }
    newsGet()
  {
    this.myservice.newsGet(data).subscribe(res=>{
      this.dataArr=res;
      
    })
  
  }}  
      

 