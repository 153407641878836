
<div class="container-fluid">

  <img src="assets/1.jpg" alt="first slide" style="display: block; width: 100%;">
  
</div>
<h1> NGP e-Smart Card </h1>
<h1> hello this is child routing </h1>
<article class="main">
 <p> 
 NGP e-Smart Card is the key to your digital life. With NGP e-Smart Card, one can avail special discount from all the business sectors listed below on corresponding products or services. Also user can pay using the points available in his / her smart card directly to the retailer / store owners. For checking Discounts in Local Stores please click on the following categories :- <br>

Become Our NGP e-Customer, NGP e-Branch, NGP e-DSO(Direct Sales Officer).<br>
<h1> Apply For NGP e-Smart Card </h1>
<div class="container-fluid">

  <img src="assets/1.jpg" alt="first slide" style="display: block; width: 100%;">
  
</div>