import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from './user';


@Injectable()
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router
  ) {}

  login(user: User) {
    if (user.username !== '' && user.password !== '' ) {
      this.loggedIn.next(true);
      this.router.navigate(['/dashboard/index']);
    }
  }

  logout() {
    this.loggedIn.next(false);
    this.router.navigate(['/home']);
  }
}


/*{
  providedIn: 'root'
})
export class AuthService {
private loggedIn : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
get isLoggedIn(){
  return this.loggedIn.asObservable();
}
  constructor( private router: Router) {}
  login(user:User){
    if (user.UserName !== '' && user.password !== ''){
      this.loggedIn.next(true);
      this.router.navigate(['/dashboard']);

    }
  }
logout(){
  debugger;
  this.loggedIn.next(false);
  this.router.navigate(['/home']);
}
}
*/
